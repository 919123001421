import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import EditExperienceForm from "./sections/EditExperienceForm";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getApi, postApi, putApi } from "../../../config/apiFunctions";
import {
  get_activities,
  get_post_detail,
  guide_upload_images,
  update_post,
} from "../../../config/endpoints";
import EditGeneralForm from "./sections/EditGeneralForm";

export default function EditExperience() {
  const User = useSelector((state) => state.auth.SignupUser);
  const [postData, setPostData] = useState();
  const formOptions = {
    resolver: yupResolver(Schema),
  };
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    control,
    trigger,
  } = useForm(formOptions);
  const { errors } = formState;
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const [acitvityList, setActivityList] = useState();
  const [selectedActivity, setSelectedActivity] = useState();
  const [selectedActivityValue, setSelectedActivityValue] = useState();
  const [selectedActivityID, setSelectedActivityID] = useState();
  const [selectedActivityValueID, setSelectedActivityValueID] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [address, setAddress] = useState();
  const [btndisable, setBtnDisable] = useState(false);
  const [toggleAccessibility, settoggleAccessibility] = useState("No");
  const [mainImgType, setMainImgType] = useState(null);
  const [mainImg, setMainImg] = useState();
  const [mainImgURL, setMainImgURL] = useState();
  const [mainImgBinary, setMainImgBinary] = useState();
  const [files, setFiles] = useState([]);
  const [multipleImages, setMultipleImages] = useState([]);
  const [addImages, setAddImages] = useState([]);
  const [multipleImagesUrl, setMultipleImagesUrl] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [activityError, setActivityError] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [getAddressData, setGetAddressData] = useState({
    lat: "",
    long: "",
    country: "",
    state: "",
    city: "",
  });

  let country = null;
  let state = null;
  let city = null;
  let lat;
  let long;

  const startDtaehandler = (date) => {
    setStartDate(date);
  };

  const handleChangePlace = (e) => {
    setInputValue(e);
    setAddress();
    setShowSuggestions(true);
    setInputError(false);
  };

  const handleToggleChange = (event) => {
    settoggleAccessibility(event.target.checked ? "Yes" : "No");
  };

  const handleSelectPlace = (address) => {
    setShowSuggestions(false);
    setAddressError(false);
    setAddress(address);
    setInputValue(address);
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]);

        const addressComponents = results[0].address_components;

        addressComponents.forEach((component) => {
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (
            component.types.includes("administrative_area_level_3") ||
            component.types.includes("postal_town")
          ) {
            city = component.long_name;
          }
        });
        lat = results[0].geometry.location.lat();
        long = results[0].geometry.location.lng();

        setGetAddressData({
          ...getAddressData,
          lat: lat,
          long: long,
          country: country,
          state: state,
          city: city || "",
        });

        // setGetAddressData({
        //   ...getAddressData,
        //   lat: lat,
        //   long: long,
        // });
      })
      .catch((error) => console.error("Error", error));
  };

  const postDetail = () => {
    getApi(get_post_detail + "/" + location?.state?.id).then((res) => {
      setPostData(res.data?.data?.postDetails);
    });
  };

  const getActivitiesList = () => {
    getApi(get_activities).then((res) => {
      if (res.status === 200) {
        setActivityList(res?.data?.data);
      }
    });
  };

  const selectMainImg = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const file = files[0];
      const fileType = file.type;

      if (fileType.startsWith("image/")) {
        setMainImgType("image");
      } else if (fileType.startsWith("video/")) {
        setMainImgType("video");
      }
      const fileUrl = URL.createObjectURL(file);
      setMainImg(fileUrl);
    }

    setMainImgBinary(e.target.files[0]);
  };

  const changeMultipleFiles = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const newFiles = filesArray.map((file) => URL.createObjectURL(file));

      setMultipleImages((prev) => [
        ...prev,
        ...filesArray.map((file, index) => ({
          url: newFiles[index],
          id: Date.now() + index,
          type: file.type,
        })),
      ]);

      setFiles((prev) => [...prev, ...filesArray]);
    }
  };

  const handleRemove = (index) => {
    setMultipleImages((current) => {
      if (current[index]?.url) {
        URL.revokeObjectURL(current[index].url);
      }
      return current.filter((_, i) => i !== index);
    });

    setFiles((current) => current.filter((_, i) => i !== index));
    setMultipleImagesUrl((current) => current.filter((_, i) => i !== index));
  };

  const onSubmit = async (e) => {
    setBtnDisable(true);

    if (
      (selectedActivityID?.length === 0 || !selectedActivityID) &&
      selectedActivityValueID?.length === 0
    ) {
      setBtnDisable(false);
      setActivityError(true);
    } else if (!inputValue) {
      setBtnDisable(false);
      setInputError(true);
    } else if (!address) {
      setBtnDisable(false);
      setAddressError(true);
    } else if (!mainImgURL && !mainImgBinary) {
      toast({
        title: "Primary media is required",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (multipleImages?.length > 4) {
      toast({
        title: "Secondary media length not be more than 4",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    }
    //  else if (files.length > 0) {
    //   const formData = new FormData();
    //   for (let i = 0; i < files.length; i++) {
    //     formData.append("upload_image", files[i]);
    //   }
    //   await postApi(guide_upload_images, formData, User?.access_token)
    //     .then((res) => {
    //       setAddImages(res?.data?.data?.insertedData);
    //       const imagesnewARR = res?.data?.data?.insertedData;

    //       const post =
    //         location?.state?.activeTab === 0 ? "GENERAL" : "EXPERIENCE";

    //       const formdata = new FormData();
    //       if (post === "GENERAL") {
    //         if (imagesnewARR && imagesnewARR.length) {
    //           for (let i = 0; i < imagesnewARR.length; i++) {
    //             formdata.append("media", imagesnewARR[i]?.url);
    //           }
    //         }
    //         if (multipleImagesUrl.length) {
    //           for (let i = 0; i < multipleImagesUrl.length; i++) {
    //             formdata.append("media", multipleImagesUrl[i]?.url);
    //           }
    //         }
    //         formdata.append("post_type", post);
    //         formdata.append("title", e.title);
    //         if (selectedActivityID) {
    //           for (let i = 0; i < selectedActivityID.length; i++) {
    //             formdata.append("activities", selectedActivityID[i]);
    //           }
    //         } else {
    //           for (let i = 0; i < selectedActivityValueID.length; i++) {
    //             formdata.append("activities", selectedActivityValueID[i]);
    //           }
    //         }
    //         formdata.append("price", e.price);
    //         formdata.append("description", e.description);
    //         formdata.append("hero_image", mainImgBinary || mainImgURL);
    //         formdata.append("location", address ? address : postData?.location);
    //         formdata.append("country", getAddressData.country || "USA");
    //         formdata.append("state", getAddressData.state || "Arizona");
    //         if (getAddressData.city) {
    //           formdata.append("city", getAddressData.city || "Arizona");
    //         }
    //         formdata.append("longitude", getAddressData.long || 111);
    //         formdata.append("latitude", getAddressData.lat || 34);
    //         formdata.append("post_id", location?.state?.id);
    //       } else {
    //         if (imagesnewARR && imagesnewARR.length) {
    //           for (let i = 0; i < imagesnewARR.length; i++) {
    //             formdata.append("media", imagesnewARR[i]?.url);
    //           }
    //         }
    //         if (multipleImagesUrl.length) {
    //           for (let i = 0; i < multipleImagesUrl.length; i++) {
    //             formdata.append("media", multipleImagesUrl[i]?.url);
    //           }
    //         }
    //         formdata.append("post_type", post);
    //         formdata.append("title", e.title);
    //         if (selectedActivityID) {
    //           for (let i = 0; i < selectedActivityID.length; i++) {
    //             formdata.append("activities", selectedActivityID[i]);
    //           }
    //         } else {
    //           for (let i = 0; i < selectedActivityValueID.length; i++) {
    //             formdata.append("activities", selectedActivityValueID[i]);
    //           }
    //         }

    //         formdata.append("schedule", e.detailed_schedule);
    //         formdata.append("transport_type", e.transport_type);
    //         formdata.append(
    //           "accessibility",
    //           toggleAccessibility === "Yes" ? true : false
    //         );
    //         formdata.append("max_people", e.max_people);
    //         formdata.append("min_people", e.min_people);
    //         formdata.append("starting_time", startDate);
    //         formdata.append("duration", e.duration);
    //         formdata.append("meeting_point", e.meeting_point);
    //         formdata.append("drop_off_point", e.drop_point);
    //         formdata.append("price", e.price);
    //         formdata.append("description", e.description);
    //         formdata.append("hero_image", mainImgBinary || mainImgURL);
    //         formdata.append("location", address ? address : postData?.location);
    //         formdata.append("country", getAddressData.country || "USA");
    //         formdata.append("state", getAddressData.state || "Arizona");
    //         if (getAddressData.city) {
    //           formdata.append("city", getAddressData.city || "Arizona");
    //         }
    //         formdata.append("longitude", getAddressData.long || 111);
    //         formdata.append("latitude", getAddressData.lat || 34);
    //         formdata.append("post_id", location?.state?.id);
    //       }
    //       putApi(`${update_post}`, formdata, User?.access_token)
    //         .then((res) => {
    //           if (res.status === 200) {
    //             toast({
    //               title: res.data.message,
    //               status: "success",
    //               duration: 30000,
    //               isClosable: true,
    //               position: "top-right",
    //               onCloseComplete: () => setBtnDisable(false),
    //             });
    //           }
    //           location?.state?.activeTab === 0
    //             ? navigate(`/view-generalPost/${location?.state?.id}`)
    //             : navigate(`/experience-detail/${location?.state?.id}`);
    //         })
    //         .catch((err) => {
    //           toast({
    //             title: err.response.data.message,
    //             status: "error",
    //             duration: 30000,
    //             isClosable: true,
    //             position: "top-right",
    //             onCloseComplete: () => setBtnDisable(false),
    //           });
    //         });
    //     })
    //     .catch((err) => {
    //       toast({
    //         title: err.response.data.message,
    //         status: "error",
    //         duration: 30000,
    //         isClosable: true,
    //         position: "top-right",
    //         onCloseComplete: () => setBtnDisable(false),
    //       });
    //     });
    // }
    else {
      const post = location?.state?.activeTab === 0 ? "GENERAL" : "EXPERIENCE";
      const formdata = new FormData();
      if (post === "GENERAL") {
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            formdata.append("media", files[i]);
          }
        }
        if (multipleImagesUrl.length > 0) {
          for (let i = 0; i < multipleImagesUrl.length; i++) {
            formdata.append("media", multipleImagesUrl[i]?.url);
          }
        }
        formdata.append("post_type", post);
        formdata.append("title", e.title);
        if (selectedActivityID) {
          for (let i = 0; i < selectedActivityID.length; i++) {
            formdata.append("activities", selectedActivityID[i]);
          }
        } else {
          for (let i = 0; i < selectedActivityValueID.length; i++) {
            formdata.append("activities", selectedActivityValueID[i]);
          }
        }
        formdata.append("price", e.price);
        formdata.append("description", e.description);
        formdata.append("hero_image", mainImgBinary || mainImgURL);
        formdata.append("location", address || postData?.location);
        formdata.append("country", getAddressData.country || "USA");
        formdata.append("state", getAddressData.state || "Arizona");
        if (getAddressData.city) {
          formdata.append("city", getAddressData.city || "Arizona");
        }
        formdata.append("longitude", getAddressData.long || 111);
        formdata.append("latitude", getAddressData.lat || 34);
        formdata.append("post_id", location?.state?.id);
      } else {
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            formdata.append("media", files[i]);
          }
        }
        if (multipleImagesUrl.length > 0) {
          for (let i = 0; i < multipleImagesUrl.length; i++) {
            formdata.append("media", multipleImagesUrl[i]?.url);
          }
        }
        formdata.append("post_type", post);
        formdata.append("title", e.title);
        if (selectedActivityID) {
          for (let i = 0; i < selectedActivityID.length; i++) {
            formdata.append("activities", selectedActivityID[i]);
          }
        } else {
          for (let i = 0; i < selectedActivityValueID.length; i++) {
            formdata.append("activities", selectedActivityValueID[i]);
          }
        }
        formdata.append("schedule", e.detailed_schedule);
        formdata.append("transport_type", e.transport_type);
        formdata.append(
          "accessibility",
          toggleAccessibility === "Yes" ? true : false
        );
        formdata.append("max_people", e.max_people);
        formdata.append("min_people", e.min_people);
        formdata.append("starting_time", startDate);
        formdata.append("duration", e.duration);
        formdata.append("meeting_point", e.meeting_point);
        formdata.append("drop_off_point", e.drop_point);
        formdata.append("price", e.price);
        formdata.append("description", e.description);
        formdata.append("hero_image", mainImgBinary || mainImgURL);
        formdata.append("location", address || postData?.location);
        formdata.append("country", getAddressData.country || "USA");
        formdata.append("state", getAddressData.state || "Arizona");
        if (getAddressData.city) {
          formdata.append("city", getAddressData.city || "Arizona");
        }
        formdata.append("longitude", getAddressData.long || 111);
        formdata.append("latitude", getAddressData.lat || 34);
        formdata.append("post_id", location?.state?.id);
      }

      putApi(`${update_post}`, formdata, User?.access_token)
        .then((res) => {
          if (res.status === 200) {
            toast({
              title: res.data.message,
              status: "success",
              duration: 30000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => setBtnDisable(false),
            });
          }
          location?.state?.activeTab === 0
            ? navigate(`/view-generalPost/${location?.state?.id}`)
            : navigate(`/experience-detail/${location?.state?.id}`);
        })
        .catch((err) =>
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          })
        );
    }
  };

  useEffect(() => {
    postDetail();
    getActivitiesList();
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (postData) {
      setValue("title", postData?.title || "");
      setSelectedActivityValue(
        postData?.posts_activities?.map((item) => item.activity)
      );
      setSelectedActivityValueID(
        postData?.posts_activities?.map((item) => item.activity?.id)
      );
      setValue("location", postData?.location || "");
      setValue("detailed_schedule", postData?.schedule || "");
      setValue("transport_type", postData?.transport_type || "");
      setValue(
        "accessibility",
        postData?.accessibility === true ? "Yes" : "No"
      );
      settoggleAccessibility(postData?.accessibility === true ? "Yes" : "No");
      setValue("max_people", postData?.max_people || "");
      setValue("min_people", postData?.min_people || "");
      setValue("starting_time", postData?.starting_time || "");
      setValue("duration", postData?.duration || "");
      setValue("meeting_point", postData?.meeting_point || "");
      setValue("drop_point", postData?.drop_off_point || "");
      setValue("price", postData?.price || "");
      setValue("description", postData?.description || "");
      setAddress(postData?.location);
      setInputValue(postData?.location);
      setStartDate(new Date(postData?.starting_time));
    }
  }, [postData, setValue, setSelectedActivityValueID]);

  useEffect(() => {
    if (postData?.hero_image) {
      setMainImgType("image");
      setMainImg(postData?.hero_image);
      setMainImgURL(postData?.hero_image);
    }
    if (postData?.post_images) {
      setMultipleImages(postData?.post_images);
      setMultipleImagesUrl(postData?.post_images);
    }
  }, [postData]);

  useEffect(() => {
    if (selectedActivityID?.length > 0 || selectedActivityValueID?.length > 0) {
      setActivityError(false);
    }
  }, [selectedActivityID, selectedActivityValueID]);

  return (
    <div className="container spacer-y contact-wrapper">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="primary__color p-0 m-0">
          {location?.state?.activeTab === 0 && "General"}
          {location?.state?.activeTab === 1 && "Experience"}
        </h2>
      </div>

      {location?.state?.activeTab === 1 && (
        <EditExperienceForm
          postData={postData}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          setValue={setValue}
          trigger={trigger}
          control={control}
          errors={errors}
          address={address}
          scriptLoaded={scriptLoaded}
          handleChangePlace={handleChangePlace}
          handleSelectPlace={handleSelectPlace}
          startDate={startDate}
          startDtaehandler={startDtaehandler}
          toggleAccessibility={toggleAccessibility}
          handleToggleChange={handleToggleChange}
          btndisable={btndisable}
          mainImg={mainImg}
          setMainImg={setMainImg}
          setMainImgURL={setMainImgURL}
          selectMainImg={selectMainImg}
          mainImgType={mainImgType}
          multipleImages={multipleImages}
          changeMultipleFiles={changeMultipleFiles}
          handleChangeRemove={handleRemove}
          acitvityList={acitvityList}
          setSelectedActivity={setSelectedActivity}
          selectedActivityValue={selectedActivityValue}
          setSelectedActivityID={setSelectedActivityID}
          setSelectedActivityValueID={setSelectedActivityValueID}
          selectedActivityValueID={selectedActivityValueID}
          inputValue={inputValue}
          showSuggestions={showSuggestions}
          addressError={addressError}
          inputError={inputError}
          activityError={activityError}
        />
      )}

      {location?.state?.activeTab === 0 && (
        <EditGeneralForm
          postData={postData}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          setValue={setValue}
          trigger={trigger}
          errors={errors}
          address={address}
          scriptLoaded={scriptLoaded}
          handleChangePlace={handleChangePlace}
          handleSelectPlace={handleSelectPlace}
          startDate={startDate}
          startDtaehandler={startDtaehandler}
          toggleAccessibility={toggleAccessibility}
          handleToggleChange={handleToggleChange}
          btndisable={btndisable}
          mainImg={mainImg}
          setMainImg={setMainImg}
          setMainImgURL={setMainImgURL}
          selectMainImg={selectMainImg}
          mainImgType={mainImgType}
          multipleImages={multipleImages}
          changeMultipleFiles={changeMultipleFiles}
          handleChangeRemove={handleRemove}
          acitvityList={acitvityList}
          setSelectedActivity={setSelectedActivity}
          selectedActivityValue={selectedActivityValue}
          setSelectedActivityID={setSelectedActivityID}
          selectedActivityValueID={selectedActivityValueID}
          setSelectedActivityValueID={setSelectedActivityValueID}
          inputValue={inputValue}
          showSuggestions={showSuggestions}
          addressError={addressError}
          inputError={inputError}
          activityError={activityError}
        />
      )}
    </div>
  );
}

const Schema = Yup.object().shape({
  // hero_img: Yup.string().required("Media is required"),
  title: Yup.string()
    .required("Title is required")
    .min(2, "Too Short!")
    .trim(" ")
    .max(50, "Please enter 50 characters only"),
  // activity: Yup.string()
  //   .required("Activity is required")
  //   .min(2, "Too Short!")
  //   .trim(" ")
  //   .max(50, "Please enter 50 characters only"),
  location: Yup.string(),
  detailed_schedule: Yup.string(),
  transport_type: Yup.string(),
  accessibility: Yup.string(),
  max_people: Yup.string(),
  min_people: Yup.string(),
  starting_time: Yup.string(),
  duration: Yup.string(),
  meeting_point: Yup.string(),
  drop_point: Yup.string(),
  price: Yup.string(),
  description: Yup.string().required("Description is required"),
});
