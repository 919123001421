import { useState } from "react";
import "./App.css";
import Routes from "./routes/Router";
import Header from "./components/layouts/header/Header";
import Footer from "./components/layouts/footer/Footer";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Dashboard from "./dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_NOTIFICATION,
  DEVICE_TOKEN,
} from "./redux/slices/auth/LoginSlice";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import { useToast } from "@chakra-ui/react";
import {
  BOOKING_HISTORY,
  PAYMENT_NOTIFICATIONS,
  RECIEVED_BOOKINGS,
} from "./redux/slices/TouristGuideReducer/guideSlice";
import {
  BOOKING,
  ALLNOTIFICATIONS,
} from "./redux/slices/TravellerReducer/travellerSlice";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import WaitingPage from "./components/main/waitingPage/WaitingPage";
import { getApi } from "./config/apiFunctions";
import { check_waiting_traveller } from "./config/endpoints";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEYS);

export default function App() {
  // const { access_token } = useSelector((state) => state.auth.SignupUser);
  const toast = useToast();
  const [notification, setNotification] = useState();
  const dispatch = useDispatch();
  const pathname = useLocation();
  const navigate = useNavigate();
  const User = useSelector((state) => state.auth.SignupUser);
  const Users = useSelector((state) => state.auth.toggle_notification);

  useEffect(() => {
    (async () => {
      const hasFirebaseMessagingSupport = await isSupported();
      if (hasFirebaseMessagingSupport) {
        const firebaseConfig = {
          apiKey: process.env.REACT_APP_API_KEY,
          authDomain: process.env.REACT_APP_AUTHDOMAIN,
          projectId: process.env.REACT_APP_PROJECT_ID,
          storageBucket: process.env.REACT_APP_STORAGEBUCKET,
          messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
          appId: process.env.REACT_APP_APPID,
          measurementId: process.env.REACT_APP_MEASUREMENTID,
        };
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPIDKEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              dispatch(DEVICE_TOKEN(currentToken));
              localStorage.setItem("device_token", currentToken);
              // console.log("access token is dispatched", currentToken);
            } else {
              // console.log(
              //   "No registration token available. Request permission to generate one."
              // );
              // shows on the UI that permission is required
            }
          })
          .catch((err) => {
            console.log(err, "fgdfg");
            dispatch(DEVICE_TOKEN([]));
            //  "An error occurred while retrieving token. ", err);
            // catch error while creating client token
          });

        onMessage(messaging, (payload) => {
          setNotification(payload);
          setNotificationUpdate(payload);
          //To dispatch realtime notification
          if (payload.data.typeMode === "BOOKING") {
            dispatch(BOOKING(traveler_booking_notification + 1));
            return;
          }
          if (payload.data.typeMode === "BOOKING_HISTORY") {
            dispatch(BOOKING_HISTORY(booking_history + 1));
            return;
          }
          if (payload.data.typeMode === "RECIEVED_BOOKING") {
            dispatch(RECIEVED_BOOKINGS(recieved_booking + 1));
            return;
          }
          if (payload.data.typeMode === "PAYMENT") {
            dispatch(PAYMENT_NOTIFICATIONS(payment + 1));
            return;
          }
          setNotificationUpdate(!notificatonUpdate);
        });
      }
    })();
  }, []);

  const [notificatonUpdate, setNotificationUpdate] = useState(null);
  const recieved_booking =
    useSelector((state) => state.touristGuide.recieved_bookings) || 0;
  const booking_history =
    useSelector((state) => state.touristGuide.booking_history) || 0;
  const traveler_booking_notification =
    useSelector((state) => state?.travellerSlice.bookings?.booking) || 0;
  const payment =
    useSelector((state) => state?.touristGuide?.payment?.payment) || 0;

  const traveller_status = useSelector((state) => state.auth.waiting_status);
  useEffect(() => {
    if (notification?.data?.typeMode === "CHAT_MESSAGE") {
      dispatch(CHAT_NOTIFICATION(notification));
    }
    if (notification) {
      if (User.notification_status === 1) {
        toast({
          title: `Notification: ${notification?.notification?.body}`,
          duration: 10000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  }, [notification, Users]);

  useEffect(() => {
    if (notificatonUpdate) {
      dispatch(ALLNOTIFICATIONS(notificatonUpdate));
      return;
    }
  }, [notificatonUpdate]);

  useEffect(() => {
    window.scrollTo(1, 0);
  }, [pathname]);

  useEffect(() => {
    AOS.init({
      duration: 900,
      throttleDelay: 99,
      // startEvent: "load",
    });
    if (recieved_booking < 1) {
      dispatch(RECIEVED_BOOKINGS(0));
    }
    if (booking_history < 1) {
      dispatch(BOOKING_HISTORY(0));
    }
    if (traveler_booking_notification < 1) {
      dispatch(BOOKING(0));
    }
  }, []);

  useEffect(() => {
    if (User?.access_token && pathname?.pathname.includes("/dashboard")) {
      document.body.classList.add("dash");
      document.body.classList.add("dashboard-module");
    } else if (!User?.access_token) {
      document.body.classList.add("website");
    } else if (
      User?.access_token &&
      !pathname?.pathname.includes("/dashboard")
    ) {
      document.body.classList.add("website");
      document.body.classList.remove("dash");
      document.body.classList.remove("dashboard-module");
    } else {
      document.body.classList.add("website");
    }
  }, [pathname?.pathname]);

  if (navigator.userAgent.indexOf("iPhone") > -1) {
    document
      .querySelector("[name=viewport]")
      .setAttribute(
        "content",
        "width=device-width, initial-scale=1, maximum-scale=1"
      );
  }

  const navigateToWait = () => {
    if (
      User?.access_token &&
      User?.role_name === "TRAVELLER" &&
      traveller_status === true &&
      pathname.pathname === "/dashboard"
    ) {
      navigate("/waiting-list");
    }
    if (
      User?.is_verified === 0 &&
      User?.role_name === "GUIDE" &&
      User?.access_token &&
      pathname.pathname === "/dashboard"
    ) {
      navigate(`guide_detailed_page/${User?.id}`);
    }
  };

  useEffect(() => {
    navigateToWait();
  }, [User, traveller_status, pathname]);

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        {User?.access_token &&
          User?.role_name === "TRAVELLER" &&
          traveller_status === true &&
          pathname?.pathname.includes("/dashboard") && (
            <>
              <WaitingPage />
            </>
          )}
        {User?.access_token &&
          !traveller_status &&
          pathname?.pathname.includes("/dashboard") && (
            <>
              <Dashboard />
            </>
          )}
        {(!User?.access_token ||
          !pathname?.pathname.includes("/dashboard")) && (
          <>
            {pathname?.pathname === "/app/terms" ||
            pathname?.pathname === "/app/privacy" ||
            pathname?.pathname === "/waiting-list" ? (
              <></>
            ) : (
              <Header />
            )}
            <div id="snackbar" className="">
              <div className="logout-notification">
                <h2 className="text-white">
                  {/* You're log out */}
                  You have successfully logged out
                </h2>
                <p className="text-white">Your session has expired</p>
              </div>
            </div>
            <Routes />
            {pathname?.pathname === "/app/terms" ||
            pathname?.pathname === "/app/privacy" ||
            pathname?.pathname === "/waiting-list" ? (
              <></>
            ) : (
              <Footer />
            )}
          </>
        )}
      </Elements>
    </div>
  );
}
