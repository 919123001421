import React, { useEffect, useState } from "react";
// import imgcover from "../../../../../../assets/images/profile-cover.png";
// import imgcover from "../../../../../../assets/images/Cover-Image-930x150.png";
import imgcover from "../../../../../../assets/images/Cover-Image-930x150.jpg";
import { useDispatch, useSelector } from "react-redux";
import imgnull from "../../../../../../assets/images/profile-placeholder1.jpg";
import { getApi, putApi } from "../../../../../../config/apiFunctions";
import {
  get_profile,
  guide_add_account,
  guide_get_account,
  // guide_get_profile,
  // travaller_get_profile,
  updateGuideProfile,
  updateTravelerProfile,
} from "../../../../../../config/endpoints";
import { SIGNUP_USER_DETAILS } from "../../../../../../redux/slices/auth/LoginSlice";
import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";
import "intl-tel-input/build/css/intlTelInput.css";
import PhoneInput from "react-phone-input-2";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import DeleteIdProof from "./DeleteIdProof";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import Commision from "./Commision";
import DeleteAccountModal from "../dashboardPage/userDashboard/modal/DeleteAccountModal";

const Setting = ({ onLoadComponent, setOnLoadComponent }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [activities, setActivities] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [isSubmit2, setSubmit2] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [state, setStateSelect] = useState([]);
  const [city, setCity] = useState([]);
  const [getCityLoader, setGetLoader] = useState({
    countries: false,
    state: false,
    city: false,
  });

  const User = useSelector((state) => state.auth.SignupUser);
  const [profilePicture, setprofilePicture] = useState([]);
  const [displayprofilePicture, setdisplayprofilePicture] = useState(
    User.profile_picture
  );
  const [addressState, setAddressState] = useState({
    country: User.country,
    state: User.state,
    city: User.city,
  });
  const [listDelete, setListDelete] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [btndisable, setBtnDisable] = useState(false);
  const [id_proofs, setId_proofs] = useState([]);
  const dispatch = useDispatch();
  const toast = useToast();
  const [edit, setEdit] = useState(false);
  const formOptions = {
    resolver: yupResolver(Schema),
    defaultValues: {
      country: User.country,
      state: User.state,
      city: User.city,
    },
  };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;

  const showedit = () => {
    if (btndisable === false) {
      setEdit(true);
      setBtnDisable(false);
    }
  };
  const Hidedit = () => {
    setEdit(false);
  };

  const getData = async () => {
    await getApi(get_profile, {}, User.access_token)
      .then((res) => {
        // console.log("hey===>", res.data.data);
        // console.log(User);
        const { data } = res.data;
        Object.keys(data).map((item) => {
          setValue(item, data[item]);
        });
        const activityArr = data?.activities?.map((item) => item.activity)
        setActivities(activityArr?.map((item) => item.id));
        updateAddress(data.country, data.state);
        setAddressState({
          ...addressState,
          country: data.country,
          state: data.state,
          city: data.city,
        });
        dispatch(SIGNUP_USER_DETAILS(res.data.data));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const updateAddress = async (con, stat) => {
    await GetCountries().then(async (countries) => {
      const country = countries.find((country) => country.name === con);
      setCountryId(country.id);
      await GetState(country.id).then(async (stateList) => {
        setStateSelect(stateList);
        const state = stateList.find((state) => state.name === stat);
        await GetCity(country.id, state.id).then((result) => {
          setCity(result);
        });
      });
    });
  };
  ///update profile picture of traveler and guide
  const handleChange = (e) => {
    setLoading(true);
    if (e) {
      setLoading(false);
      let file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setdisplayprofilePicture(url);
      setprofilePicture(e.target.files[0]);
    }
  };

  //phone no inputhandler
  const [contactDetails, setContatDetails] = useState({
    phone: "",
    isoCode: User.country_code_iso,
    dialCode: User.country_code,
  });

  const onContactNoChange = (value, country, e, formattedValue) => {
    const { dialCode: country_code, countryCode: country_iso_code } = country;
    const _phone = value.slice(country_code.length);
    setContatDetails({
      ...contactDetails,
      phone: _phone,
      isoCode: country_iso_code,
      dialCode: `+${country_code}`,
    });
    setValue("phone", _phone);
    trigger("phone");
  };
  //update userDetails of traveler and guide
  const onSubmit = (e) => {
    setSubmit(true);
    setBtnDisable(true);
    delete e.email;
    const access_token = User.access_token;
    let api = "";
    if (User.role_name === "GUIDE") {
      api = updateGuideProfile;
    } else {
      api = updateTravelerProfile;
    }
    const formData = new FormData();
    if (User.role_name === "GUIDE") {
      for (let i = 0; i < files.length; i++) {
        formData.append("id_proof", files[i]);
      }
    }

    for (let i = 0; i < activities.length; i++) {
      formData.append("activities", activities[i]);
    }
    formData.append("name", e.name);
    if (e.last_name !== undefined) {
      formData.append("last_name", e.last_name);
    }

    formData.append("phone", e.phone);
    formData.append("country_code", contactDetails.dialCode);
    formData.append("country_code_iso", contactDetails.isoCode);
    formData.append("profile_picture", profilePicture);
    formData.append("country", e.country);
    formData.append("state", e.state);
    formData.append("city", e.city);
    formData.append("bio", e.bio);

    if (User.role_name === "GUIDE") {
      formData.append("price", e.price || 23);
      formData.append("currency", "USD");
      formData.append("pincode", e.pincode);
    }

    putApi(api, formData, access_token)
      .then((res) => {
        Hidedit();
        let data = res.data.data.urls;
        for (let i = 0; i < data?.length; i += 1) {
          setId_proofs((prevArray) => [...prevArray, data[i]]);
        }
        setEdit(false);
        setSubmit(false);
        const user = {
          ...User,
          name: e.name,
          last_name: e.last_name !== undefined ? e.last_name : User.last_name,
          phone: e.phone,
          country_code: contactDetails.dialCode,
          country_code_iso: contactDetails.isoCode,
          profile_picture: res?.data?.data?.image_url
            ? res?.data?.data?.image_url
            : User.profile_picture,
          country: e.country,
          state: e.state,
          city: e.city,
          bio: e.bio,
          // price: User.role_name === "GUIDE" ? e.price : User.price,
          // currency: User.role_name === "GUIDE" ? "USD" : User.currency,
          pincode: User.role_name === "GUIDE" ? e.pincode : User.pincode,
        };
        dispatch(SIGNUP_USER_DETAILS(user));
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        setEdit(false);
        getData();
      })
      .catch((err) => {
        setSubmit(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });

    // Bank details

    setSubmit2(true);
    setBtnDisable(true);
    const obj = {
      account_number: e.account_number,
      account_user_name: e.acc_name,
      bank_name: e.bank_name,
      bank_ifsc: e.bank_code,
    };
    if (User.role_name === "GUIDE") {
      putApi(guide_add_account, obj, User.access_token)
        .then((res) => {
          setSubmit2(false);
          // toast({
          //   title: res.data.message,
          //   status: "success",
          //   duration: 30000,
          //   isClosable: true,
          //   position: "top-right",
          //   onCloseComplete: () => setBtnDisable(false),
          // });
          setSubmit2(false);
        })
        .catch((err) => {
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          setSubmit2(false);
        });
    }
  };

  const changeMultipleFiles = (e) => {
    //setMultipleImages([]);
    if (e.target.files) {
      const imageArray = Array.from(e.target.files).map((file, index) => {
        setFiles((prev) => [...prev, file]);
        const arr = { document_url: URL.createObjectURL(file) };
        setMultipleImages((prev) => [...prev, arr]);

        // setMultipleImages({ document_url: e.target.files});
      });
    }
  };

  const handleChangeRemove = (i) => {
    setShowDelete(true);
    setFiles([]);
    setMultipleImages(id_proofs);
    setListDelete(i);
  };
  // const handleRemove = (i) => {
  //   const data = [...id_proofs];
  //   const newarr = data?.filter((item) => item.document_url !== i.document_url);
  //   setId_proofs(newarr);
  // };

  ///add bank details...........
  const onSubmit2 = (e) => {
    setSubmit2(true);
    setBtnDisable(true);
    const obj = {
      account_number: e.account_number,
      account_user_name: e.acc_name,
      bank_name: e.bank_name,
      bank_ifsc: e.bank_code,
    };
    putApi(guide_add_account, obj, User.access_token)
      .then((res) => {
        setSubmit2(false);
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        setSubmit2(false);
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        setSubmit2(false);
      });
  };
  const [acc, setAcc] = useState(null);

  //disable scroll on input type number
  const handleChangee = (e) => {
    const numberInput = document.getElementById("numberInput2");
    numberInput?.addEventListener("wheel", (e) => {
      e.preventDefault();
    });

    if (e.target.value) {
      const numberInput = document.getElementById("numberInput");
      numberInput.addEventListener("wheel", (event) => {
        event.preventDefault();
      });
    }
  };
  // Custom validation resolver
  const isSpecialChar = (key) => {
    // Regular expression to match special characters
    const specialChars = /[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return specialChars.test(key);
  };
  const restrictCharacters = (event) => {
    const keyCode = event.key;
    if (
      (isNaN(parseInt(keyCode, 10)) || keyCode === " ") &&
      keyCode !== "Backspace"
    ) {
      event.preventDefault();
    }
  };

  const preventPaste = (event) => {
    event.preventDefault();
  };
  const setCityList = async (event) => {
    const { value } = event.target;
    setAddressState({
      ...addressState,
      city: value,
    });
  };
  const getCityList = async (event) => {
    setGetLoader({ ...getCityLoader, ["city"]: true });
    const { value } = event.target;
    const selectedState = state.find((state) => state.name === value);
    await GetCity(countryId, selectedState.id).then((result) => {
      setCity(result);
      setAddressState({
        ...addressState,
        state: value,
        city: result[0]?.name,
      });
    });
    setGetLoader({ ...getCityLoader, ["city"]: false });
  };
  const getStateList = async (event) => {
    // setAddressState({...addressState,})
    // console.log(event, "heyyyyyyy");
    setGetLoader({ ...getCityLoader, ["state"]: true });
    const { value } = event.target;
    // setCity([]);
    const country = countries.find((country) => country.name === value);
    setCountryId(country.id);
    await GetState(country.id).then(async (stateList) => {
      setStateSelect(stateList);
      setGetLoader({ ...getCityLoader, ["city"]: true });
      await GetCity(country.id, stateList[0]?.id).then((cityList) => {
        setCity(cityList);
        setAddressState({
          ...addressState,
          country: value,
          state: stateList[0]?.name,
          city: cityList[0]?.name,
        });
      });
      setGetLoader({ ...getCityLoader, ["city"]: false });
    });
    setGetLoader({ ...getCityLoader, ["state"]: false });
  };
  const getCountriesList = async () => {
    setGetLoader({ ...getCityLoader, ["countries"]: true });
    await GetCountries().then((result) => {
      setCountries(result);
    });
    setGetLoader({ ...getCityLoader, ["countries"]: false });
  };

  const getDefaultAddress = async () => {
    await GetCountries().then(async (countries) => {
      setCountries(countries);
      let countryQuery = User.country ? User.country : "United States";
      const country = countries.find(
        (country) => country.name === countryQuery
      );
      setCountryId(country.id);
      await GetState(country.id).then(async (states) => {
        setStateSelect(states);
        let stateQuery = User.state ? User.state : states[0].name;
        const state = states.find((state) => state.name === stateQuery);
        await GetCity(country.id, state.id).then((result) => {
          setCity(result);
        });
      });
    });
  };
  useEffect(() => {
    getDefaultAddress();
    if (User.role_name === "GUIDE") {
      getApi(guide_get_account, {}, User.access_token).then((res) => {
        setAcc(JSON.parse(res.data.data.value));
      });
    }
    reset();
    setId_proofs(User?.userDocuments);
    getData();
  }, []);

  useEffect(() => {
    // if (multipleImages.length !== 0) {
    setMultipleImages(id_proofs);
    const user = { ...User };
    const newobj = (user.userDocuments = id_proofs);
    dispatch(SIGNUP_USER_DETAILS(user));
  }, [edit, id_proofs]);

  //disable enable input fields
  useEffect(() => {
    let defaultValues = {};
    defaultValues.name = User?.name;
    defaultValues.email = User?.email;
    defaultValues.phone = `${User.phone}`;
    // defaultValues.price = `${User.price}`;
    // defaultValues.pincode = `${User.pincode}`;
    if (User.role_name === "TRAVELLER") {
      defaultValues.price = `11`;
      defaultValues.pincode = `1234567890`;
      defaultValues.acc_name = "XYZ";
      defaultValues.account_number = "00000000";
      defaultValues.bank_name = "XYZ";
      defaultValues.bank_code = "ICIC0001234";
    }
    reset({ ...defaultValues });
    if (edit === true) {
      document.querySelector(".flag-dropdown ").style.pointerEvents = "auto";
      const inputs = document.querySelectorAll(
        "#user-profile, #numberInput,#numberInput2, .react-tel-input input "
      );
      for (var i = 0, len = inputs.length; i < len; i++) {
        inputs[i].disabled = false;
        inputs.style = "d-none";
      }
    } else if (edit === false) {
      document.querySelector(".flag-dropdown ").style.pointerEvents = "none";
      const inputs = document.querySelectorAll(
        "#user-profile ,#numberInput,#numberInput2, .react-tel-input input"
      );
      for (var i = 0, len = inputs.length; i < len; i++) {
        inputs[i].disabled = true;
      }
    }
  }, [edit]);

  return (
    <div className="booking_innerbox">
      <div className="profile_box">
        <div
          className="profile_coverbox"
          style={{ backgroundImage: `url(${imgcover})` }}
        ></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="profile_setting p-20">
            <div className="profile_header">
              <div className="profile_header_box d-flex align-items-center justify-content-between">
                <div className="profile_imgbox d-flex align-items-center me-2">
                  <div className="position-relative">
                    <div className="user_img position-relative">
                      <input
                        id="user-profile"
                        disabled
                        type="file"
                        name="profile-pic"
                        accept="image/*"
                        className="profile-pic"
                        onChange={handleChange}
                      />
                      {isLoading ? (
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="3"
                          //animationDuration="0.75"
                          width="150"
                          visible={true}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={
                            !displayprofilePicture
                              ? imgnull
                              : displayprofilePicture
                          }
                          alt="profile-img"
                        />
                      )}
                    </div>
                    <div
                      className={
                        edit === true ? "edit_imgbtn" : "edit_imgbtn d-none"
                      }
                    >
                      <span className="edit_img">
                        <label htmlFor="user-profile">
                          <i className="fa-regular fa-camera"></i>
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="profile_detail pt-4 ms-3 mt-2">
                    <h4 className="text-black-light f-700 mb-1 lh-1">
                      {!User.last_name
                        ? User.name
                        : `${User.name} ${User.last_name}`}
                    </h4>
                    <p className=" looged_user_role text-grey-light mb-0">
                      {User.role_name === "TRAVELLER" ? "Traveler" : "Guide"}
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <a
                    id=""
                    className={
                      edit === true
                        ? "edit_information btn-main btn-primary-light mt-3 f-600 d-none"
                        : "edit_information btn-main btn-primary-light mt-3 f-600"
                    }
                    onClick={showedit}
                  >
                    {/* Edit Profile */}
                    Edit
                  </a>
                  <div
                    className={
                      edit === true ? "hide-controls" : "hide-controls d-none"
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mt-3">
                        <a
                          href=""
                          className="btn-main btn_outline me-3 cancel-edit"
                          onClick={Hidedit}
                        >
                          Cancel
                        </a>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        {/* <a
                        href=""
                        className="btn-main btn-primary cancel-edit"
                        // onClick={Hidedit}
                      >
                        Save Changes
                      </a> */}
                        <button
                          htmlFor="btn"
                          name="btn"
                          disabled={btndisable}
                          className="btn-main btn-primary w-100 mw-100"
                        >
                          {isSubmit ? (
                            <ThreeDots
                              height="20"
                              width="80"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          ) : (
                            "   Save Changes"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3 mx-2">
                    <button
                      type="button"
                      // disabled={btndisable}
                      onClick={() => setDeleteModal(true)}
                      className="btn-main btn-red w-100 mw-100"
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile_body mt-4">
              <div className="user_profile_box">
                <div className="title_box mb-3">
                  <h3 className="text-black-light f-700 mb-1 lh-1">
                    {/* Profile Details */}
                    Account Details
                  </h3>
                  <p className="text-grey-light mb-0"></p>
                </div>
                <div className="form_box_m w-100">
                  {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        id="user-profile"
                        className="input-box d-flex align-items-start justify-content-start flex-column mb-4"
                      >
                        <label
                          className="small-text text-grey-scale mb-1 f-600"
                          htmlFor="name"
                        >
                          First name
                        </label>
                        <input
                          {...register("name")}
                          id="user-profile"
                          disabled
                          type="text"
                          name="name"
                          placeholder="Enter name"
                          className={`small-text form-control ${errors.name ? "is-invalid" : ""
                            }`}
                        />
                        <div className="invalid-feedback">
                          {errors.name?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                        <label
                          className="small-text text-grey-scale mb-1 f-600"
                          htmlFor="Last name"
                        >
                          Last name
                        </label>
                        <input
                          id="user-profile"
                          disabled
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          {...register("last_name")}
                          className={`small-text form-control ${errors.last_name ? "is-invalid" : ""
                            }`}
                        />
                        <div className="invalid-feedback">
                          {errors.last_name?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="email"
                      >
                        Email id
                      </label>
                      <input
                        disabled
                        type="email"
                        name="email"
                        placeholder="Enter email id"
                        defaultValue={User?.email}
                        {...register("email")}
                        className={`form-control ${errors.email ? "is-invalid" : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="Phone email"
                      >
                        Phone number
                      </label>

                      <PhoneInput
                        id="user-profile"
                        // country={contactDetails?.isoCode}
                        value={`${User.country_code}${User.phone}`}
                        placeholder="Enter contact number"
                        countryCodeEditable={false}
                        enableSearch={true}
                        onChange={(value, country, e, formattedValue) =>
                          onContactNoChange(value, country, e, formattedValue)
                        }
                        sx={{ width: "100%" }}
                      />
                      <input
                        {...register("phone")}
                        type="hidden"
                      //defaultValue={User?.phone}
                      />
                      {errors.phone && (
                        <div
                          className="text-danger mt-1"
                          sx={{ ml: "1px" }}
                          error={true}
                          style={{ fontSize: ".875em" }}
                        >
                          Enter valid Phone Number
                        </div>
                      )}
                      {/* </div> */}
                    </div>
                  </div>

                  <div>
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="email"
                      >
                        Country
                      </label>
                      <select
                        {...register("country")}
                        disabled
                        onChange={(e) => getStateList(e)}
                        id="user-profile"
                        name="country"
                        value={addressState.country}
                        className={`form-control ${errors.country ? "is-invalid" : ""
                          }`}
                      >
                        {getCityLoader.country && (
                          <option value={""}>Loading...</option>
                        )}

                        {/* <option defaultValue={User?.country}>
                          {User?.country}
                        </option> */}
                        {countries.length > 0 &&
                          countries.map((country, index) => {
                            return (
                              <option value={country.name} key={index}>
                                {country.name}
                              </option>
                            );
                          })}
                      </select>
                      <div className="invalid-feedback">
                        {errors.country?.message}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div
                        id="user-profile"
                        className="input-box d-flex align-items-start justify-content-start flex-column mb-4"
                      >
                        <label
                          className="small-text text-grey-scale mb-1 f-600"
                          htmlFor="name"
                        >
                          State
                        </label>
                        <select
                          {...register("state")}
                          id="user-profile"
                          disabled
                          onChange={(e) => getCityList(e)}
                          name="state"
                          value={addressState?.state}
                          className={`small-text form-control ${errors.state ? "is-invalid" : ""
                            }`}
                        >
                          {getCityLoader.state && (
                            <option value={""}>Loading...</option>
                          )}
                          {/* 
                          <option defaultValue={User?.state}>
                            {User?.state}
                          </option> */}

                          {state.length > 0 &&
                            state.map((state, index) => {
                              return (
                                <option value={state.name} key={index}>
                                  {state.name}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">
                          {errors.state?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                        <label
                          className="small-text text-grey-scale mb-1 f-600"
                          htmlFor="Last name"
                        >
                          City
                        </label>
                        <select
                          {...register("city")}
                          id="user-profile"
                          disabled
                          onChange={setCityList}
                          value={addressState.city}
                          name="city"
                          className={`small-text form-control ${errors.city ? "is-invalid" : ""
                            }`}
                        >
                          {getCityLoader.city && (
                            <option value={""}>Loading...</option>
                          )}

                          {/* <option defaultValue={User?.city}>
                            {User?.city}
                          </option> */}
                          {city.length > 0 &&
                            city.map((city, index) => {
                              return (
                                <option value={city.name} key={index}>
                                  {city.name}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">
                          {errors.city?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  {User.role_name === "GUIDE" && (
                    <>
                      <div>
                        {/* <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                          <label
                            className="small-text text-grey-scale mb-1 f-600"
                            htmlFor="price"
                          >
                            Price (Hourly Rate){" "}
                            <span style={{ fontSize: "12px" }}>
                              (Siesta will deduct <Commision /> commission)
                            </span>
                          </label>
                          <input
                            id="numberInput"
                            disabled
                            type="number"
                            name="price"
                            placeholder="Enter per hour price"
                            defaultValue={User?.price}
                            // {...register("price")}
                            {...register("price", {
                              onChange: handleChangee,
                            })}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                            onPaste={(event) => preventPaste(event)}
                            className={`form-control ${
                              errors.price ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.price?.message}
                          </div>
                        </div> */}
                        <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                          <label
                            className="small-text text-grey-scale mb-1 f-600"
                            htmlFor="pincode"
                          >
                            Zipcode
                          </label>
                          <input
                            id="user-profile"
                            disabled
                            type="text"
                            name="pincode"
                            placeholder="Enter your zipcode"
                            defaultValue={User?.pincode}
                            {...register("pincode")}
                            className={`form-control ${errors.pincode ? "is-invalid" : ""
                              }`}
                            // onKeyDown={(evt) => ["@","!", "#", "$", "-", "%", "&", "^", "*", "(",")","-", "+", "=", "_", "/", "`", "~"].includes(evt.key) && evt.preventDefault()}
                            onKeyDown={(evt) =>
                              isSpecialChar(evt.key) && evt.preventDefault()
                            }
                          />
                          <div className="invalid-feedback">
                            {errors.pincode?.message}
                          </div>
                        </div>

                        <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                          <label
                            className="small-text text-grey-scale mb-1 f-600"
                            htmlFor="bio"
                          >
                            Bio
                          </label>
                          <textarea
                            id="user-profile"
                            disabled
                            type="text"
                            name="bio"
                            // placeholder="Enter your zipcode"
                            defaultValue={User?.bio}
                            {...register("bio")}
                            className={`form-control ${errors.bio ? "is-invalid" : ""
                              }`}
                          />
                          <div className="invalid-feedback">
                            {errors.bio?.message}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="input-box d-flex align-items-start justify-content-start flex-column">
                          <label
                            className="small-text text-grey-scale mb-1 f-600"
                            htmlFor="Id-Proof"
                          >
                            Id proof
                          </label>
                          <div className="uploaded_proof w-100">
                            <div
                              className="fileUpload w-100 d-flex align-items-center justify-content-center flex-column"
                              id=""
                            >
                              <div
                                className="w-100"
                                encType="multipart/form-data"
                                id=""
                              >
                                <div className="profile_proof_inner show_uploaded_img">
                                  <div className="proof_gallerybox d-flex flex-wrap align-items-center justify-content-start">
                                    {multipleImages?.map((idProofs, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className={
                                            edit === false
                                              ? "proof_img"
                                              : "proof_img show_overlay"
                                          }
                                        >
                                          <img
                                            className="img-fluid"
                                            src={idProofs.document_url}
                                            alt="proof"
                                          />
                                          <span
                                            className={
                                              edit === false
                                                ? "remove_imgbtn d-none"
                                                : "remove_imgbtn"
                                            }
                                            onClick={
                                              () => handleChangeRemove(idProofs)
                                              // handleRemove(idProofs)
                                            }
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 8 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M3.99935 7.33341C5.83268 7.33341 7.33268 5.83341 7.33268 4.00008C7.33268 2.16675 5.83268 0.666748 3.99935 0.666748C2.16602 0.666748 0.666016 2.16675 0.666016 4.00008C0.666016 5.83341 2.16602 7.33341 3.99935 7.33341Z"
                                                stroke="white"
                                                strokeWidth="0.7"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M3.05664 4.94331L4.94331 3.05664"
                                                stroke="white"
                                                strokeWidth="0.7"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M4.94331 4.94331L3.05664 3.05664"
                                                stroke="white"
                                                strokeWidth="0.7"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div
                                    className={
                                      edit === true
                                        ? "upload_proof_btn"
                                        : "upload_proof_btn d-none"
                                    }
                                  >
                                    {id_proofs?.length >= 4 ? (
                                      <label
                                        className="proof_label w-100 text-center d-flex flex-column align-items-center justify-content-center p-30"
                                        htmlFor="galleryImageUpload"
                                      >
                                        <a className="text-primary">
                                          Maximum Document Limit is 4
                                          <p>
                                            You have reached the maximum number
                                            of documents that can be uploaded.
                                            Please remove some documents before
                                            adding new ones.
                                          </p>
                                        </a>
                                      </label>
                                    ) : (
                                      <label
                                        className="proof_label w-100 text-center d-flex flex-column align-items-center justify-content-center p-30"
                                        htmlFor="galleryImageUpload"
                                      >
                                        <svg
                                          className="mb-2"
                                          width="26"
                                          height="21"
                                          viewBox="0 0 26 21"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M21.4878 6.95801C20.3103 2.03288 15.5535 -0.957466 10.8629 0.278856C7.19739 1.24504 4.53579 4.57156 4.26156 8.52935C1.65887 8.98003 -0.103108 11.5608 0.326105 14.2936C0.707655 16.7232 2.71257 18.5024 5.05759 18.4926H9.03777V16.821H5.05759C3.29905 16.821 1.87345 15.3241 1.87345 13.4776C1.87345 11.6311 3.29905 10.1342 5.05759 10.1342C5.49725 10.1342 5.85363 9.75998 5.85363 9.29833C5.84965 5.1437 9.05404 1.77231 13.0108 1.76818C16.4359 1.76457 19.3844 4.30712 20.0469 7.83559C20.1124 8.1879 20.3847 8.45705 20.7236 8.50427C22.8998 8.82968 24.4128 10.9459 24.1029 13.2309C23.8246 15.2828 22.1561 16.8106 20.1823 16.821H16.9981V18.4926H20.1823C23.2597 18.4829 25.747 15.8554 25.7376 12.624C25.7298 9.93415 23.9764 7.59633 21.4878 6.95801Z"
                                            fill="#98A2B3"
                                          ></path>
                                          <path
                                            d="M12.4537 10.3764L9.26953 13.7198L10.3919 14.8983L12.2228 12.9842V21H13.8149V12.9842L15.6378 14.8983L16.7602 13.7198L13.5761 10.3764C13.2656 10.0523 12.7642 10.0523 12.4537 10.3764Z"
                                            fill="#98A2B3"
                                          ></path>
                                        </svg>
                                        <span className="small-text f-500 lh-24">
                                          <a className="text-primary">
                                            Click to upload
                                          </a>{" "}
                                          <br></br> JPG or PNG (maximum size
                                          10MB)
                                        </span>
                                        <input
                                          name="id_proof"
                                          id="galleryImageUpload"
                                          className="d-none"
                                          type="file"
                                          accept="image/*"
                                          multiple
                                          {...register("id_proof", {
                                            onChange: changeMultipleFiles,
                                          })}
                                        />
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div>
                    <div
                      className={
                        edit === true
                          ? "update_profile_btn mt-4"
                          : "update_profile_btn d-none mt-4"
                      }
                    >
                      <button
                        disabled={btndisable}
                        className="btn-main btn-primary w-100 mw-100"
                        type="submit"
                      >
                        {isSubmit ? (
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          "   Save Changes"
                        )}
                      </button>
                    </div>
                  </div> */}
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          {User.role_name === "GUIDE" && (
            // <form className="p-20" onSubmit={handleSubmit(onSubmit2)}>
            <div className="p-20">
              <div className="title_box mb-3 mt-5 ">
                <h3 className="text-black-light f-700 mb-1 lh-1">
                  Bank Details{" "}
                  <span className="mb-0 text-extra">
                    (For direct deposit of your earnings)
                  </span>
                </h3>

                <p className="text-grey-light mb-0"></p>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div
                    id="user-profile"
                    className="input-box d-flex align-items-start justify-content-start flex-column mb-4"
                  >
                    <label
                      className="small-text text-grey-scale mb-1 f-600"
                      htmlFor="name"
                    >
                      Account Holder Name
                    </label>
                    <input
                      id="user-profile"
                      disabled
                      type="text"
                      name="acc_name"
                      placeholder="Enter name"
                      defaultValue={acc?.account_user_name}
                      {...register("acc_name")}
                      className={`small-text form-control ${errors.acc_name ? "is-invalid" : ""
                        }`}
                      onKeyDown={(evt) =>
                        isSpecialChar(evt.key) && evt.preventDefault()
                      }
                    />
                    <div className="invalid-feedback">
                      {errors.acc_name?.message}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                    <label
                      className="small-text text-grey-scale mb-1 f-600"
                      htmlFor="Last name"
                    >
                      Account Number
                    </label>
                    <input
                      id="numberInput2"
                      onWheel="wheel"
                      disabled
                      type="text"
                      pattern="[0-9]*"
                      name="account_number"
                      placeholder="Enter account number"
                      defaultValue={acc?.account_number}
                      {...register("account_number", {
                        onChange: handleChangee,
                      })}
                      className={`small-text form-control ${errors.account_number ? "is-invalid" : ""
                        }`}
                      onKeyDown={(event) => restrictCharacters(event)}
                      onPaste={(event) => preventPaste(event)}
                      maxLength={18}
                    />
                    <div className="invalid-feedback">
                      {errors.account_number?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    id="user-profile"
                    className="input-box d-flex align-items-start justify-content-start flex-column mb-4"
                  >
                    <label
                      className="small-text text-grey-scale mb-1 f-600"
                      htmlFor="name"
                    >
                      Bank Name
                    </label>
                    <input
                      id="user-profile"
                      disabled
                      type="text"
                      name="bank_name"
                      placeholder="Enter Bank Name"
                      defaultValue={acc?.bank_name}
                      {...register("bank_name")}
                      className={`small-text form-control ${errors.bank_name ? "is-invalid" : ""
                        }`}
                      onKeyDown={(evt) =>
                        isSpecialChar(evt.key) && evt.preventDefault()
                      }
                    />
                    <div className="invalid-feedback">
                      {errors.bank_name?.message}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                    <label
                      className="small-text text-grey-scale mb-1 f-600"
                      htmlFor="Last name"
                    >
                      Routing Number
                    </label>
                    <input
                      id="user-profile"
                      disabled
                      type="text"
                      name="bank_code"
                      placeholder="Enter routing number"
                      defaultValue={acc?.bank_ifsc}
                      {...register("bank_code")}
                      className={`small-text form-control ${errors.bank_code ? "is-invalid" : ""
                        }`}
                      onKeyDown={(evt) =>
                        isSpecialChar(evt.key) && evt.preventDefault()
                      }
                      onPaste={(event) => preventPaste(event)}
                    />
                    <div className="invalid-feedback">
                      {errors.bank_code?.message}
                    </div>
                  </div>
                </div>
              </div>
              {/* {edit === true && (
              <button
                id="user-profile"
                disabled={btndisable}
                className="btn-main btn-primary w-50 mw-100"
                type="submit"
              >
                {isSubmit2 ? (
                  <ThreeDots
                    height="20"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Save Bank Details"
                )}
              </button>
            )} */}
            </div>
          )}
        </form>
      </div>
      <DeleteIdProof
        show={showDelete}
        onHide={() => setShowDelete(false)}
        id_proofs={id_proofs}
        listDelete={listDelete}
        setId_proofs={setId_proofs}
        setEdit={() => setEdit(false)}
      />
      <DeleteAccountModal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
      />
    </div>
  );
};

export default Setting;
const Schema = Yup.object().shape({
  name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .trim(" ")
    .max(15, "Please enter 15 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only")
    .matches(/^[A-Z]/, "First letter should be uppercase."),
  last_name: Yup.string()
    .max(15, "Please enter 15 characters only")
    .matches(/^[A-Za-z\s]*$/, "Please enter letters only"),
  // price: Yup.string()
  //   .required("Price is required")
  //   .max(5, "Please enter valid amount")
  //   .test("Is positive?", "Please enter valid amount", (value) => value > 0),
  pincode: Yup.string()
    .required("Zipcode is required")
    .matches(/^[a-zA-Z0-9]{5,10}$/, "Please enter valid pincode"),
  phone: Yup.string()
    .required("Phone no is required.")
    .min(10, "Enter valid phone number"),
  acc_name: Yup.string(),
  // .required("Account holder name is required")
  // .min(2, "Account holder name should be at least 2 characters")
  // .max(50, "Account holder name should not exceed 50 characters")
  // .matches(/^[A-Za-z\s]*$/, "Please enter letters only")
  // .matches(/^[A-Z]/, "First letter should be uppercase."),
  account_number: Yup.string(),
  // .matches(/^\d+$/, "Account number must be a whole number")
  // .required("Account number is required")
  // .min(8, "Account number should be at least 8 characters")
  // .max(17, "Account number should not exceed 17 characters"),
  bank_name: Yup.string(),
  // .required("Bank name is required")
  // .min(2, "Bank name should be at least 2 characters")
  // .trim(" ")
  // .max(30, "Bank name should not exceed 30 characters")
  // .matches(/^[A-Za-z\s]*$/, "Please enter letters only"),
  bank_code: Yup.string(),
  // .required("Routing number is required")
  // .matches(
  //   /^[A-Z]{4}0[A-Z0-9]{6}$/,
  //   "Invalid Routing Number format"
  // ),
  // .matches(/^[A-Za-z0-9]{5,20}$/, "Invalid Routing Number format"),
  country: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "Country",
    // })
  ),
  state: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "State",
    // })
  ),
  city: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "City",
    // })
  ),
  bio: Yup.string(),
});
