import React from "react";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import defaultCoverImg from "../../../../assets/images/counter-bg.png";
import defaultProfile from "../../../../assets/images/profile-placeholder1.jpg";

export default function LocaliteBanner({
  guideDetailData,
  updateCoverImage,
  handleRemoveCover,
  loading,
  handleChangeProfile,
}) {
  const User = useSelector((state) => state.auth.SignupUser);

  return (
    <div className="localite_banner">
      <img
        src={
          guideDetailData?.guideDetails?.user_detail?.cover_picture ||
          defaultCoverImg
        }
        alt="BannerImg"
      />

      <div className="host_img">
        <img
          src={!guideDetailData?.guideDetails?.user_detail?.profile_picture ? defaultProfile : guideDetailData?.guideDetails?.user_detail?.profile_picture}
          alt="Host Img"
        />
      </div>

      {/* {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            margin: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          {guideDetailData?.guideDetails?.user_detail?.cover_picture ? (
            <>
              <img
                src={guideDetailData.guideDetails.user_detail.cover_picture}
                alt="BannerImg"
              />
              {User.role_name === "GUIDE" && (
                <div className="delete_img" onClick={handleRemoveCover}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="rgba(136,22,22,1)"
                  >
                    <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                  </svg>
                </div>
              )}
            </>
          ) : User.role_name === "GUIDE" ? (
            <label
              className="upload_media_label w-100 text-center d-flex flex-column align-items-center justify-content-center p-30 rounded-0 h-100"
              htmlFor="coverpicture"
            >
              <img src={DragDrop} className="drag_icon" alt="" />
              <p className="small-text fw-bold lh-24 m-0">
                Click to upload cover picture
              </p>
              <input
                name="coverpicture"
                id="coverpicture"
                className="d-none"
                type="file"
                accept="image/*"
                multiple
                onChange={updateCoverImage}
              />
            </label>
          ) : (
            <img src={defaultImg} alt="BannerImg" />
          )}
        </>
      )} */}

      {/* <div className="host_img">
        <img
          src={
            guideDetailData?.guideDetails?.user_detail?.profile_picture ||
            defaultCoverImg
          }
          alt="Host Img"
        />
        {User.role_name === "GUIDE" && (
          <label
            className=" w-100 text-center d-flex flex-column align-items-center justify-content-center p-30"
            htmlFor="profilePicture"
          >
            <div className="edit_hero_imgbtn" role="button">
              <span className="edit_hero_img">
                <i className="fa-regular fa-camera"></i>
                <input
                  name="profilePicture"
                  id="profilePicture"
                  className="d-none"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleChangeProfile}
                />
              </span>
            </div>
          </label>
        )}
      </div> */}
    </div>
  );
}
