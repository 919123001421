import React, { useEffect, useState } from "react";
import "./index.css";
import Profile from "./sections/profileSection/Profile";
import MediaSection from "./sections/mediaSection/MediaSection";
import WhatYouDo from "./sections/youDoSection/WhatYouDo";
import YourHost from "./sections/yourHost/YourHost";
import ReviewRating from "./sections/reviewAndRating/ReviewRating";
import SimilarExperience from "./sections/similarExp/SimilarExperience";
import { getApi } from "../../../config/apiFunctions";
import { get_post_detail } from "../../../config/endpoints";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";

export default function ExperienceDetailsPage() {
  const User = useSelector((state) => state.auth.SignupUser);
  const { id } = useParams();
  const [postData, setPostData] = useState();
  const [similarPostData, setSimilarPostData] = useState();
  const [loading, setLoading] = useState(false);

  const postDetail = () => {
    setLoading(true);
    getApi(get_post_detail + "/" + id).then((res) => {
      setPostData(res.data?.data?.postDetails);
      setSimilarPostData(res.data?.data?.similarPosts);
      setLoading(false);
    });
  };

  useEffect(() => {
    postDetail();
  }, [id]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <section className="hero">
          <div className="container spacer-y">
            {/* {User?.role_name !== "GUIDE" && <Profile postData={postData} />} */}

            <MediaSection postData={postData} />
            <WhatYouDo postData={postData} />
            {User?.role_name !== "GUIDE" && <YourHost postData={postData} />}
            {postData?.user?.rating_and_reviews.length > 0 && (
              <ReviewRating postData={postData} />
            )}
            {/* {User?.role_name !== "GUIDE" && (
              <SimilarExperience similarPostData={similarPostData} />
            )} */}
          </div>
        </section>
      )}
    </>
  );
}
