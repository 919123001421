import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getApi } from "../../../config/apiFunctions";
import { guide_get_generalPost } from "../../../config/endpoints";
import { Oval } from "react-loader-spinner";

export default function GeneralPostPage() {
  const User = useSelector((state) => state.auth.SignupUser);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [Imgdata, setImgdata] = useState();

  const getGallery = () => {
    setLoading(true);
    const access_token = User.access_token;
    getApi(`${guide_get_generalPost}/${id}`, {}, access_token).then((res) => {
      setImgdata(res?.data?.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getGallery();
  }, []);

  const activityArr = Imgdata?.postDetails?.posts_activities?.map(
    (item) => item.activity
  );

  return (
    <div className="container spacer-y contact-wrapper">
      <div className="text-center">
        <h2 className="p-0">General Planner Details</h2>
        <p>Check out the detail of place and experience</p>
      </div>

      {isLoading === true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="gallery_content pt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="top-sticky">
                <div className="d-flex gap-4 justify-content-between">
                  <h5 className="mb-2">{Imgdata?.postDetails?.title}</h5>
                  <div className="d-flex gap-2">
                    {User?.role_name === "GUIDE" &&
                      !location?.state?.fromPreview &&
                      !location?.state?.formSocialProfile && (
                        <Link
                          to={`/edit-experience/${Imgdata?.postDetails?.id}`}
                          state={{ activeTab: 0, id: Imgdata?.postDetails?.id }}
                          className="icon_button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="white"
                          >
                            <path d="M12.8995 6.85453L17.1421 11.0972L7.24264 20.9967H3V16.754L12.8995 6.85453ZM14.3137 5.44032L16.435 3.319C16.8256 2.92848 17.4587 2.92848 17.8492 3.319L20.6777 6.14743C21.0682 6.53795 21.0682 7.17112 20.6777 7.56164L18.5563 9.68296L14.3137 5.44032Z"></path>
                          </svg>
                        </Link>
                      )}
                    <Link onClick={() => navigate(-1)} className="icon_button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="white"
                      >
                        <path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path>
                      </svg>
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="rgba(71,84,103,1)"
                  >
                    <path d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"></path>
                  </svg>
                  <p className="m-0 ps-2">
                    {Imgdata?.postDetails?.location || "USA"}
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="rgba(71,84,103,1)"
                  >
                    <path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM6 9C5.44772 9 5 9.44772 5 10V14H7V10C7 9.44772 6.55228 9 6 9ZM19 5H10V3H20C20.5523 3 21 3.44772 21 4V15C21 15.5523 20.5523 16 20 16H16.5758L19.3993 22H17.1889L14.3654 16H10V14H19V5Z"></path>
                  </svg>
                  <p className="m-0 ps-2">
                    {activityArr
                      ?.map((item) => item?.title)
                      .join(", ")
                      .replace(/, ([^,]*)$/, " and $1")}
                  </p>
                </div>

                <div className="d-flex align-items-center pb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="rgba(71,84,103,1)"
                  >
                    <path d="M12.0049 22.0027C6.48204 22.0027 2.00488 17.5256 2.00488 12.0027C2.00488 6.4799 6.48204 2.00275 12.0049 2.00275C17.5277 2.00275 22.0049 6.4799 22.0049 12.0027C22.0049 17.5256 17.5277 22.0027 12.0049 22.0027ZM12.0049 20.0027C16.4232 20.0027 20.0049 16.421 20.0049 12.0027C20.0049 7.58447 16.4232 4.00275 12.0049 4.00275C7.5866 4.00275 4.00488 7.58447 4.00488 12.0027C4.00488 16.421 7.5866 20.0027 12.0049 20.0027ZM8.50488 14.0027H14.0049C14.281 14.0027 14.5049 13.7789 14.5049 13.5027C14.5049 13.2266 14.281 13.0027 14.0049 13.0027H10.0049C8.62417 13.0027 7.50488 11.8835 7.50488 10.5027C7.50488 9.12203 8.62417 8.00275 10.0049 8.00275H11.0049V6.00275H13.0049V8.00275H15.5049V10.0027H10.0049C9.72874 10.0027 9.50488 10.2266 9.50488 10.5027C9.50488 10.7789 9.72874 11.0027 10.0049 11.0027H14.0049C15.3856 11.0027 16.5049 12.122 16.5049 13.5027C16.5049 14.8835 15.3856 16.0027 14.0049 16.0027H13.0049V18.0027H11.0049V16.0027H8.50488V14.0027Z"></path>
                  </svg>
                  <p className="m-0 ps-2">
                    {`${Imgdata?.postDetails?.price} / person`}
                  </p>
                </div>
                <p>{Imgdata?.postDetails?.description}</p>
              </div>
            </div>
            <div className="col-md-6">
              {Imgdata?.postDetails?.post_images?.length === 4 && (
                <>
                  <div className="gallery_main_img">
                    {/* {Imgdata?.postDetails?.hero_image?.endsWith(".mp4") ||
                    Imgdata?.postDetails?.hero_image?.endsWith(".webm") ? (
                      <div className="heroexp_image_section">
                        <video controls className="w-100 h-100">
                          <source
                            src={Imgdata?.postDetails?.hero_image}
                            type={Imgdata?.postDetails?.hero_image.type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : ( */}
                      <div className="heroexp_image_section">
                        <img
                          src={Imgdata?.postDetails?.hero_image}
                          alt="hero_image"
                        />
                      </div>
                    {/* )} */}
                  </div>

                  <div className="row g-3 mt-0">
                    <div className="col-md-6 gap-2">
                      <div className="gallery_first_img">
                        {Imgdata?.postDetails?.post_images[0]?.media_type === "video" ? (
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.postDetails?.post_images[0]?.url}
                              type={
                                Imgdata?.postDetails?.post_images[0]?.url?.type
                              }
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={Imgdata?.postDetails?.post_images[0]?.url}
                            alt="img"
                          />
                        )}
                      </div>
                      <div className="row g-3 mt-0">
                        <div className="col-md-6 gap-2">
                          {Imgdata?.postDetails?.post_images[1] && (
                            <div className="gallery_sec_img">
                              {Imgdata?.postDetails?.post_images[1]?.media_type === "video" ? (
                                <video controls className="w-100 h-100">
                                  <source
                                    src={
                                      Imgdata?.postDetails?.post_images[1]?.url
                                    }
                                    type={
                                      Imgdata?.postDetails?.post_images[1]?.url
                                        ?.type
                                    }
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={
                                    Imgdata?.postDetails?.post_images[1]?.url
                                  }
                                  alt="img"
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          {Imgdata?.postDetails?.post_images[2] && (
                            <div className="gallery_sec_img">
                              {Imgdata?.postDetails?.post_images[2]?.media_type === "video" ? (
                                <video controls className="w-100 h-100">
                                  <source
                                    src={
                                      Imgdata?.postDetails?.post_images[2]?.url
                                    }
                                    type={
                                      Imgdata?.postDetails?.post_images[2]?.url
                                        ?.type
                                    }
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={
                                    Imgdata?.postDetails?.post_images[2]?.url
                                  }
                                  alt="img"
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {Imgdata?.postDetails?.post_images[3] && (
                        <div className="gallery_last_img">
                          {Imgdata?.postDetails?.post_images[3]?.media_type === "video" ? (
                            <video controls className="w-100 h-100">
                              <source
                                src={Imgdata?.postDetails?.post_images[3]?.url}
                                type={
                                  Imgdata?.postDetails?.post_images[3]?.url
                                    ?.type
                                }
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={Imgdata?.postDetails?.post_images[3]?.url}
                              alt="img"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {Imgdata?.postDetails?.post_images?.length === 3 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.postDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.postDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.postDetails?.hero_image}
                              type={Imgdata?.postDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.postDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                  {Imgdata?.postDetails?.post_images?.map((item, i) => (
                    <div key={i} className="col-4">
                      <div className="gallery_first_img">
                        {item?.media_type === "video" ? (
                          <video controls className="w-100 h-100">
                            <source src={item?.url} type={item?.url?.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={item?.url} alt="img" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {Imgdata?.postDetails?.post_images?.length === 2 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.postDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.postDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.postDetails?.hero_image}
                              type={Imgdata?.postDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.postDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                  {Imgdata?.postDetails?.post_images?.map((item, i) => (
                    <div key={i} className="col-6">
                      <div className="gallery_first_img">
                        {item?.media_type === "video" ? (
                          <video controls className="w-100 h-100">
                            <source src={item?.url} type={item?.url?.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={item?.url} alt="img" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {Imgdata?.postDetails?.post_images?.length === 1 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.postDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.postDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.postDetails?.hero_image}
                              type={Imgdata?.postDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.postDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                  {Imgdata?.postDetails?.post_images?.map((item, i) => (
                    <div key={i} className="col-12">
                      <div className="gallery_first_img">
                        {item?.media_type === "video" ? (
                          <video controls className="w-100 h-100">
                            <source src={item?.url} type={item?.url?.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={item?.url} alt="img" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {Imgdata?.postDetails?.post_images?.length === 0 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.postDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.postDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.postDetails?.hero_image}
                              type={Imgdata?.postDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.postDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
