import React, { createRef, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { postApi } from "../../../config/apiFunctions";
import { check_waiting_traveller, login } from "../../../config/endpoints";
import {
  SIGNUP_USER_DETAILS,
  WAITING_STATUS,
} from "../../../redux/slices/auth/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import ForgotPassword from "../../auth/forgotPassword/ForgotPassword";

export default function LoginModal(props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmit, setSubmit] = useState(false);
  const [btndisable, setBtnDisable] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [showforgetPass, setShowForgetPass] = useState(false);
  const [showpopup, setshowpopup] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [show, setShow] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  const device_token = useSelector((state) => state.auth.device_token);

  const User = useSelector((state) => state.auth.SignupUser);

  const checkhandle = () => {
    setCheckbox(!checkbox);
  };

  const handleShowForgetPass = () => {
    setShowForgetPass(true);
    setshowpopup(true);
    setConfirm(false);
  }; 

  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const recaptchaRef = createRef();
  const onChangeCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const handlepass = () => {
    setToggle(!toggle);
  };

  const onSubmit = (e) => {
    recaptchaRef.current?.reset();
    setCaptchaValue("");
    setBtnDisable(true);
    if (checkbox === false) {
      toast({
        title: "Please agree all the term and conditions.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (!captchaValue) {
      toast({
        title: "CAPTCHA verification failed. Please try again.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else {
      setSubmit(true);
      setToggle(true);
      let obj = {};
      if (device_token.length === 0) {
        obj = {
          email: e.email,
          password: e.password,
          application_type: 1,
          recaptcha_value: captchaValue,
          // device_token: device_token,
        };
      } else {
        obj = {
          email: e.email,
          password: e.password,
          application_type: 1,
          device_token: device_token,
          recaptcha_value: captchaValue,
        };
      }
      postApi(login, obj)
        .then((res) => {
          setShow(true);
          setSubmit(false);
          if (res.status === 200 && res.data.data.role_name === "TRAVELLER") {
            const obj = {
              id: res.data.data.id,
              email: res.data.data.email,
            };
            if (res.data.data.id) {
              postApi(`${check_waiting_traveller}`, obj)
                .then((res) => {
                  dispatch(WAITING_STATUS(res?.data?.data?.waiting_list));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
          if (res.status === 200 && res.data.data.role_name === "GUIDE") {
            dispatch(WAITING_STATUS(false));
          }
          if (res.status === 200 && res.data.data.access_token !== undefined) {
            setSubmit(false);
            setBtnDisable(false);
            setTimeout(() => {
              setShow(false);
              dispatch(SIGNUP_USER_DETAILS(res.data.data));
              // navigate("/dashboard");
              props.onHide()
            }, 1500);
            reset();
          } else if (
            res.status === 200 &&
            res.data.data.access_token === undefined &&
            res.data.data.role_name === "TRAVELLER"
          ) {
            if (res.data.data.waiting_list) {
              dispatch(SIGNUP_USER_DETAILS(res.data.data));
              setBtnDisable(false);
              setSubmit(false);
              toast({
                title: res.data.message,
                status: "success",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });
              navigate("/waiting-list");
            } else {
              dispatch(SIGNUP_USER_DETAILS(res.data.data));
              setBtnDisable(false);
              setSubmit(false);
              toast({
                title: res.data.message,
                status: "success",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });

              navigate("/create_traveler_profile");
            }
          } else if (
            res.status === 200 &&
            res.data.data.access_token === undefined &&
            res.data.data.role_name === "GUIDE"
          ) {
            dispatch(SIGNUP_USER_DETAILS(res.data.data));
            setSubmit(false);
            toast({
              title: res.data.message,
              status: "success",
              duration: 30000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => setBtnDisable(false),
            });

            navigate("/create_guide_profiles");
          }
          setBtnDisable(false);
          setSubmit(false);
        })
        .catch((err) => {
          setSubmit(false);
          toast({
            title: err?.response?.data?.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        });
    }
  };

  const checkingTravellerStatus = () => {
    const obj = {
      id: User.id,
      email: User.email,
    };
    if (User.id) {
      postApi(`${check_waiting_traveller}`, obj, User.access_token)
        .then((res) => {
          dispatch(WAITING_STATUS(res?.data?.data?.waiting_list));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (User.role_name === "TRAVELLER") {
      checkingTravellerStatus();
    }
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="modal-width-custom"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content p-30">
          <div className="modal-header position-relative">
            <div class="">
              <h3 className="f-700 mb-2">Login</h3>
              <p className="p-0 m-0">Login and enjoy staying at locals!</p>
              </div>

            <button
              type="button"
              className="btn-close"
              onClick={() => props.onHide()}
            ></button>
          </div>
          <div className="modal-body">
            <form className="traveler-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row gy-3 gy-lg-4">
                <div className="col-lg-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    {...register("email")}
                    className={`form-control theme-controls ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    //  className="form-control theme-controls"
                    placeholder="you@company.com"
                  />
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Password
                  </label>
                  <div className="input-icon-grp">
                    <input
                      type={toggle === true ? "password" : "text"}
                      name="password"
                      {...register("password")}
                      className={`form-control theme-controls ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      // className="form-control theme-controls"
                      placeholder="8 character or more"
                    />
                    <i
                      className={
                        toggle === true
                          ? "fa-light  fa-eye-slash show-pass"
                          : "fa-light  fa-eye show-pass"
                      }
                      onClick={handlepass}
                    ></i>
                    <div className="invalid-feedback">
                      {errors.password?.message}
                    </div>
                  </div>
                  <Link
                    type="button"
                    className="text-end d-block mt-3 muted-text text-sm forgot-pass-trigger color__paragrap primary__color"
                    onClick={handleShowForgetPass}
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className="col-lg-12">
                  <div className="checkbox-wrap">
                    <label className="checkbox-container">
                      <input type="checkbox" onChange={checkhandle} />
                      <div className="checkmark"></div>
                    </label>
                    <p className="mb-0">
                      By Log in, you agree to our{" "}
                      <a href="/terms" className="primary__color">
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a href="/privacy" className="primary__color">
                        Privacy Notice
                      </a>
                    </p>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChangeCaptcha}
                  />
                </div>

                <div className="col-lg-12">
                  <button
                    type="submit"
                    disabled={btndisable}
                    className="button-primary w-100 next-step d-flex justify-content-center "
                  >
                    {isSubmit ? (
                      <ThreeDots
                        height="20"
                        width="80"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      "Log In"
                    )}
                  </button>
                  {/* <Success show={show} onHide={() => setShow(false)} /> */}
                  {/* <EmailVerificationPopup
                    show={show}
                    onHide={() => setShow(false)}
                  /> */}
                </div>
                <div className="col-lg-12">
                  <p className="text-sm mb-0 text-center">
                    Don't have an account yet?{" "}
                    <Link to="/signup" className="primary__color fw-bold">
                      Sign up
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <ForgotPassword
        setshowpopup={setshowpopup}
        showpopup={showpopup}
        show={showforgetPass}
        confirm={confirm}
        setConfirm={setConfirm}
        handleOverlay={() => {
          setShowForgetPass(false);
          setshowpopup(false);
        }}
      />
    </>
  );
}
const Schema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});
