import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postApi } from "../../../config/apiFunctions";
import { signup } from "../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import TravelerForm from "./travelerForm/TravelerForm";
import GuideForm from "./guideForm/GuideForm";
import { persistor } from "../../../redux/store/store";
import { Link, useLocation } from "react-router-dom";
import { PROFILE_PIC } from "../../../redux/slices/profilePicture/ProfilePictureSlice";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { emailRegExp, phoneRegExp } from "../../../config/common/common";
import { phone } from "phone";

const SignUp = () => {
  const { state } = useLocation();
  // const { name } = location.state || {};

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const toast = useToast();
  const [show, setShow] = useState(false);
  // const [activeTab, setActiveTab] = useState(state?.name ? 2 : 1);
  const [activeTab, setActiveTab] = useState(
    state?.name || state?.fromLangingPage ? 2 : 1
  );
  const [toggle, setToggle] = useState(true);
  const [toggle1, setToggle1] = useState(true);
  const [isSubmit, setSubmit] = useState(false);
  const [role, setRole] = useState(
    state?.name || state?.fromLangingPage ? "GUIDE" : "TRAVELLER"
  );
  const [btndisable, setBtnDisable] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [phoneCheckbox, setPhoneCheckbox] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formEvents, setFormEvents] = useState();
  const [captchaValue, setCaptchaValue] = useState("");
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const [password, setPassword] = useState("");

  const checkhandle = () => {
    setCheckbox(!checkbox);
  };
  const phoneCheckHandle = () => {
    setPhoneCheckbox(!phoneCheckbox);
  };

  const handlepass = () => {
    setToggle(!toggle);
  };
  const handlepass1 = () => {
    setToggle1(!toggle1);
  };
  useEffect(() => {
    setValue("password", password);
  }, []);

  const recaptchaRef = createRef();
  const onChangeCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const [contactDetails, setContatDetails] = useState({
    phone: "",
    isoCode: "+1",
    dialCode: "us",
  });
  const onContactNoChange = (value, country, e, formattedValue) => {
    setPhoneError(phone(`+${value}`).isValid);
    const { dialCode: country_code, countryCode: country_iso_code } = country;
    const _phone = value.slice(country_code.length);
    setContatDetails({
      ...contactDetails,
      phone: _phone,
      isoCode: country_iso_code,
      dialCode: country_code,
    });
    setValue("phone", _phone);
    trigger("phone");
  };

  useEffect(() => {
    if (activeTab === 1) {
      reset();
      setPassword("");
      setCheckbox(false);
      setPhoneCheckbox(false);
    } else {
      reset();
      setPassword("");
      setCheckbox(false);
      setPhoneCheckbox(false);
    }
  }, [activeTab]);

  const onSubmit = (e) => {
    recaptchaRef.current?.reset();
    setCaptchaValue("");
    // persistor.purge();
    // dispatch(PROFILE_PIC([]));
    // setBtnDisable(true);
    if (checkbox === false) {
      toast({
        title: "Please agree all the term and conditions.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (phoneCheckbox === false) {
      toast({
        title: "Please agree for receiving SMS text from Siesta.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (!captchaValue) {
      toast({
        title: "CAPTCHA verification failed. Please try again.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else {
      setConfirmModal(true);
      setSubmit(true);
      setToggle(true);
      setToggle1(true);
      const obj = {
        // ...e,
        name: e.name,
        last_name: e.last_name || "",
        email: e.email,
        role_name: role,
        password: e.password,
        phone: e.phone,
        country_code: `+${contactDetails.dialCode}`,
        country_code_iso: contactDetails.isoCode,
        recaptcha_value: captchaValue,
      };
      setFormEvents(obj);
      // delete obj.cpassword;
      // postApi(signup, obj)
      //   .then((res) => {
      //     setBtnDisable(false);
      //     setSubmit(false);
      //     if (res.status === 200) {
      //       setBtnDisable(false);
      //       setShow(true);
      //       reset();
      //     }
      //   })
      //   .catch((err) => {
      //     setConfirmModal(false);
      //     // setBtnDisable(false);
      //     setSubmit(false);
      //     toast({
      //       title: err.response.data.message,
      //       status: "error",
      //       duration: 30000,
      //       isClosable: true,
      //       position: "top-right",
      //       onCloseComplete: () => setBtnDisable(false),
      //     });
      //   });
    }
  };

  const submitData = () => {
    setBtnDisable(true);

    setCaptchaValue("");
    persistor.purge();
    dispatch(PROFILE_PIC([]));
    delete formEvents.cpassword;
    postApi(signup, formEvents)
      .then((res) => {
        setBtnDisable(false);
        setSubmit(false);
        if (res.status === 200) {
          setBtnDisable(false);
          setShow(true);
          setConfirmModal(false);
          reset();
        }
        reset();
      })
      .catch((err) => {
        setConfirmModal(false);
        // setBtnDisable(false);
        setSubmit(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        reset();
      });
    setValue("phone_condition", false);
    setValue("terms_condition", false);
    setPhoneCheckbox(false);
    setCheckbox(false);
  };

  return (
    <>
      <section className="login-signup-wrapper signup-page">
        <div className="container-fluid px-0 spacer-y">
          <div className="row gy-4 gy-lg-0 gx-0 align-items-center zindex-up">
            <div className="col-lg-6 pe-lg-5 order-2 order-lg-1 spacing-left">
              <h2 className="pb-0 mb-3">Sign Up</h2>
              {/* <p>Sign up and enjoy staying at locals.</p> */}
              {activeTab === 1 ? (
                <p>Sign up and enjoy staying at locals.</p>
              ) : (
                <p>Sign up to be the localite.</p>
              )}
              <div className="tab-teaser">
                {!state?.fromLangingPage && (
                  <div className="tab-menu">
                    <ul>
                      <li
                        onClick={(e) => {
                          setActiveTab(1);
                          setToggle(true);
                          setToggle1(true);
                          setRole("TRAVELLER");
                        }}
                      >
                        <Link
                          name="role_name"
                          {...register("role_name")}
                          className={`${activeTab === 1 ? "active" : ""} ${
                            errors.role_name ? "is-invalid" : ""
                          }`}
                        >
                          Traveler
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setActiveTab(2);
                          setToggle(true);
                          setToggle1(true);
                          setRole("GUIDE");
                        }}
                      >
                        <Link
                          name="role_name"
                          data-rel="tab-2"
                          className={activeTab === 2 ? "active" : ""}
                        >
                          {/* Tourist Guide */}
                          Localite
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
                <div className="tab-main-box">
                  {activeTab === 1 && (
                    <TravelerForm
                      onSubmit={onSubmit}
                      handleSubmit={handleSubmit}
                      register={register}
                      setValue={setValue}
                      trigger={trigger}
                      errors={errors}
                      toggle={toggle}
                      contactDetails={contactDetails}
                      onContactNoChange={onContactNoChange}
                      phoneError={phoneError}
                      toggle1={toggle1}
                      handlepass={handlepass}
                      handlepass1={handlepass1}
                      checkhandle={checkhandle}
                      phoneCheckHandle={phoneCheckHandle}
                      btndisable={btndisable}
                      isSubmit={isSubmit}
                      show={show}
                      setShow={setShow}
                      setPassword={setPassword}
                      password={password}
                      onChangeCaptcha={onChangeCaptcha}
                      recaptchaRef={recaptchaRef}
                      checkbox={checkbox}
                      phoneCheckbox={phoneCheckbox}
                    />
                  )}
                  {activeTab === 2 && (
                    <GuideForm
                      onSubmit={onSubmit}
                      handleSubmit={handleSubmit}
                      register={register}
                      errors={errors}
                      toggle={toggle}
                      toggle1={toggle1}
                      handlepass={handlepass}
                      handlepass1={handlepass1}
                      checkhandle={checkhandle}
                      phoneCheckHandle={phoneCheckHandle}
                      btndisable={btndisable}
                      isSubmit={isSubmit}
                      show={show}
                      setShow={setShow}
                      setPassword={setPassword}
                      password={password}
                      setValue={setValue}
                      trigger={trigger}
                      contactDetails={contactDetails}
                      onContactNoChange={onContactNoChange}
                      phoneError={phoneError}
                      onChangeCaptcha={onChangeCaptcha}
                      recaptchaRef={recaptchaRef}
                      checkbox={checkbox}
                      phoneCheckbox={phoneCheckbox}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="aside-image"></div>
            </div>
          </div>
        </div>
        <div className="gradient-overlay hero"></div>
      </section>

      {/* <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-content text-center p-5">
            <div className="modal_header position-relative">
              <h4 className="text-grey-500 f-700 mb-2">
                {activeTab === 1
                  ? "Please confirm you want to sign up as a Traveller with Siesta Global"
                  : "Please confirm you want to sign up as a localite with Siesta Global"}
              </h4>
            </div>

            <div className="row ">
              <div className="col-md-6">
                {btndisable === true ? (
                  <button
                    type="submit"
                    disabled={btndisable}
                    className="btn-main btn-primary min-w button-primary"
                  >
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    className="btn-main btn-primary min-w button-primary"
                    onClick={submitData}
                  >
                    Yes
                  </button>
                )}
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  onClick={() => setConfirmModal(false)}
                  className="btn-main btn-red min-w"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-0"
      >
        <div
          className={
            confirmModal === true
              ? "p-5 text-center"
              : "modal fade p-5 text-center"
          }
          id="cancel_trip"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal_header position-relative">
            {activeTab === 1 ? (
              <h5 className="text-grey-500 mb-2">
                Please confirm you want to sign up as a Traveler with Siesta
                Travel
              </h5>
            ) : (
              <h5 className="text-grey-500 mb-2">
                Please confirm you want to sign up as a localite with Siesta
              </h5>
            )}
          </div>
          <div className="modal-footer justify-content-center border-0 p-0 pt-3">
            <a
              className="btn-main btn-red min-w "
              onClick={() => setConfirmModal(false)}
            >
              Cancel
            </a>
            <button
              className="btn-main btn-primary min-w button-primary"
              type="submit"
              onClick={submitData}
            >
              {btndisable === true ? (
                <ThreeDots
                  height="20"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SignUp;
const Schema = Yup.object().shape({
  name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .max(20, "Please enter 20 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only")
    .matches(/^[A-Z]/, "First letter should be uppercase."),
  last_name: Yup.string()
    .max(20, "Please enter 20 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  email: Yup.string()
    .required("Email is required")
    .matches(emailRegExp, "Please enter valid email address"),
  password: Yup.string().required("Password is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  // .min(8, "Password must be atleast 8 characters long")
  // .matches(
  //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  // )
  role_name: Yup.string(),
  terms_condition: Yup.boolean().required(),
  phone_condition: Yup.boolean().required(),
  cpassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be atleast 8 characters long")
    .matches(
      /^(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password")], "Passwords does not match"),
});
