import React, { useEffect, useState } from "react";
import "./index.css";
import LocaliteBanner from "./section/LocaliteBanner";
import AboutLocalite from "./section/AboutLocalite";
import LocaliteExp from "./section/LocaliteExp";
import LocaliteSpecialExp from "./section/LocaliteSpecialExp";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApi,
  getApi,
  postApi,
  putApi,
} from "../../../config/apiFunctions";
import {
  delete_cover_image,
  editGuideProfilePic,
  follow,
  get_experience_post,
  get_gallery_detail,
  get_general_post,
  get_guide_profile_detail,
  guide_get_profile,
  like_gallery,
  like_post,
  unfollow,
  unlike_gallery,
  unlike_post,
  upload_cover_image,
} from "../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import { SIGNUP_USER_DETAILS } from "../../../redux/slices/auth/LoginSlice";
import { useParams } from "react-router-dom";
import Gallery from "./section/Gallery";
import RatingReview from "./section/RatingReview";
import { Oval } from "react-loader-spinner";

export default function PreviewProfilePage() {
  const [loading, setLoading] = useState(false);
  const [Imgdata, setImgdata] = useState();
  const [guideDetailData, setGuideDetailData] = useState();
  const [generalPostData, setGeneralPostData] = useState();
  const [experiencePostData, setExperiencePostData] = useState();
  const [galleryPageNo, setGalleryPageNo] = useState(1);
  const [totalGalleryPageNo, setTotalGalleryPageNo] = useState();
  const [generalPageNo, setgeneralPageNo] = useState(1);
  const [totalGeneralPageNo, setTotalGeneralPageNo] = useState();
  const [experiencePageNo, setExperiencePageNo] = useState(1);
  const [totalExperiencePageNo, setTotalExperiencePageNo] = useState();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const User = useSelector((state) => state.auth.SignupUser);
  const toast = useToast();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleLikeUnlike = (id, liked) => {
    const obj = {
      post_id: id,
      liked_by_user_id: User.id,
    };
    const apiCall = liked === 1 ? unlike_post : like_post;

    postApi(apiCall, obj).then((res) => {
      if (res.status === 200) {
        getGuideDetails();
        getGeneralPosts();
        getExperiencePosts();
      }
    });
  };

  const handleLikeUnlikeGallery = (id, liked) => {
    const obj = {
      gallery_id: id,
      liked_by_user_id: User.id,
    };
    const apiCall = liked === 1 ? unlike_gallery : like_gallery;

    postApi(apiCall, obj).then((res) => {
      if (res.status === 200) {
        getGuideDetails();
        getGallery();
      }
    });
  };

  const updateCoverImage = (e) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("cover_picture", e.target.files[0]);
    putApi(upload_cover_image, formdata, User.access_token).then((res) => {
      if (res.status === 200) {
        toast({
          title: res?.data?.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
        getGuideDetails();
        setLoading(false);
      }
    });
  };

  const handleRemoveCover = () => {
    setLoading(true);
    deleteApi(`${delete_cover_image}`, {}, User.access_token).then((res) => {
      if (res.status === 200) {
        toast({
          title: res?.data?.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
        getGuideDetails();
        setLoading(false);
      }
    });
  };

  const handleChangeProfile = (e) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("profile_picture", e.target.files[0]);
    putApi(editGuideProfilePic, formdata, User.access_token).then((res) => {
      toast({
        title: res.data.message,
        status: "success",
        duration: 30000,
        isClosable: true,
        position: "top-right",
      });
      const updatedUser = {
        ...User,
        profile_picture: res?.data?.data?.image_url,
      };
      dispatch(SIGNUP_USER_DETAILS(updatedUser));
      setLoading(false);
      getGuideDetails();
    });
  };

  const handleNextGeneralPage = () => {
    setgeneralPageNo((prevPageNo) => prevPageNo + 1);
  };

  const handlePreviousGeneralPage = () => {
    if (generalPageNo > 1) {
      setgeneralPageNo((prevPageNo) => prevPageNo - 1);
    }
  };

  const handleNextGalleryPage = () => {
    setGalleryPageNo((prevPageNo) => prevPageNo + 1);
  };

  const handlePreviousGalleryPage = () => {
    if (galleryPageNo > 1) {
      setGalleryPageNo((prevPageNo) => prevPageNo - 1);
    }
  };

  const handleNextExperiencePage = () => {
    setExperiencePageNo((prevPageNo) => prevPageNo + 1);
  };

  const handlePreviousExperiencePage = () => {
    if (experiencePageNo > 1) {
      setExperiencePageNo((prevPageNo) => prevPageNo - 1);
    }
  };

  const getGuideDetails = () => {
    setLoading(true);
    if (User.role_name === "GUIDE") {
      getApi(guide_get_profile, {}, User.access_token).then((res) => {
        if (res.status === 200) {
          setGuideDetailData(res?.data?.data);
          setLoading(false);
        }
      });
    } else {
      getApi(
        `${get_guide_profile_detail}?guideId=${id}&userId=${User.id}`,
        {},
        User.access_token
      ).then((res) => {
        if (res.status === 200) {
          setGuideDetailData(res?.data?.data);
          setLoading(false);
        }
      });
    }
  };

  const getGallery = () => {
    setLoading(true);

    User.id
      ? getApi(
          `${get_gallery_detail}?id=${
            User.role_name === "GUIDE" ? User.id : id
          }&page_no=${galleryPageNo}&number_of_rows=3&user_id=${User.id}`
        ).then((res) => {
          setImgdata(res?.data?.data?.rows);
          setLoading(false);
          setTotalGalleryPageNo(res?.data?.data?.totalCounts / 3);
        })
      : getApi(
          `${get_gallery_detail}?id=${
            User.role_name === "GUIDE" ? User.id : id
          }&page_no=${galleryPageNo}&number_of_rows=3&user_id=${id}`
        ).then((res) => {
          setImgdata(res?.data?.data?.rows);
          setLoading(false);
          setTotalGalleryPageNo(res?.data?.data?.totalCounts / 3);
        });
  };

  const getGeneralPosts = () => {
    setLoading(true);
    User.id
      ? getApi(
          `${get_general_post}?id=${
            User.role_name === "GUIDE" ? User.id : id
          }&page_no=${generalPageNo}&number_of_rows=3&user_id=${User.id}`
        ).then((res) => {
          setGeneralPostData(res?.data?.data);
          setTotalGeneralPageNo(res?.data?.data?.totalCounts / 3);
          setLoading(false);
        })
      : getApi(
          `${get_general_post}?id=${
            User.role_name === "GUIDE" ? User.id : id
          }&page_no=${generalPageNo}&number_of_rows=3`
        ).then((res) => {
          setGeneralPostData(res?.data?.data);
          setTotalGeneralPageNo(res?.data?.data?.totalCounts / 3);
          setLoading(false);
        });
  };

  const getExperiencePosts = () => {
    setLoading(true);
    User.id
      ? getApi(
          `${get_experience_post}?page_no=${experiencePageNo}&number_of_rows=4&id=${
            User.role_name === "GUIDE" ? User.id : id
          }&user_id=${User.id}`
        ).then((res) => {
          setExperiencePostData(res?.data?.data);
          setTotalExperiencePageNo(res?.data?.data?.totalCounts / 4);
          setLoading(false);
        })
      : getApi(
          `${get_experience_post}?page_no=${experiencePageNo}&number_of_rows=4&id=${
            User.role_name === "GUIDE" ? User.id : id
          }`
        ).then((res) => {
          setExperiencePostData(res?.data?.data);
          setTotalExperiencePageNo(res?.data?.data?.totalCounts / 4);
          setLoading(false);
        });
  };

  const followGuide = () => {
    if (User.access_token && User.role_name === "TRAVELLER") {
      const obj = {
        follower_id: User.id,
        followed_id: guideDetailData?.guideDetails?.id,
      };

      const api = guideDetailData?.isFollowed ? unfollow : follow;
      postApi(api, obj).then((res) => {
        if (res.status === 200) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
        }
        getGuideDetails();
      });
    } else {
      setShowLoginModal(true);
    }
  };

  useEffect(() => {
    getGuideDetails();
    getGallery();
    getGeneralPosts();
    getExperiencePosts();
  }, [generalPageNo, experiencePageNo, galleryPageNo]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <LocaliteBanner
            guideDetailData={guideDetailData}
            handleRemoveCover={handleRemoveCover}
            updateCoverImage={updateCoverImage}
            loading={loading}
            handleChangeProfile={handleChangeProfile}
          />
          <div className="container spacer-y">
            <AboutLocalite
              guideDetailData={guideDetailData}
              followGuide={followGuide}
              showLoginModal={showLoginModal}
              setShowLoginModal={setShowLoginModal}
            />
            {Imgdata?.length > 0 && (
              <Gallery
                guideDetailData={guideDetailData}
                Imgdata={Imgdata}
                handleNextPage={handleNextGalleryPage}
                handlePreviousPage={handlePreviousGalleryPage}
                pageNo={galleryPageNo}
                total={totalGalleryPageNo}
                handleLikeUnlike={handleLikeUnlikeGallery}
              />
            )}
            {generalPostData?.rows?.length > 0 && (
              <LocaliteExp
                guideDetailData={guideDetailData}
                generalPostData={generalPostData}
                handleNextPage={handleNextGeneralPage}
                handlePreviousPage={handlePreviousGeneralPage}
                pageNo={generalPageNo}
                total={totalGeneralPageNo}
                handleLikeUnlike={handleLikeUnlike}
              />
            )}

            {experiencePostData?.rows?.length > 0 && (
              <LocaliteSpecialExp
                experiencePostData={experiencePostData}
                guideDetailData={guideDetailData}
                handleNextPage={handleNextExperiencePage}
                handlePreviousPage={handlePreviousExperiencePage}
                pageNo={experiencePageNo}
                total={totalExperiencePageNo}
                handleLikeUnlike={handleLikeUnlike}
              />
            )}

            {guideDetailData?.guideDetails?.rating_and_reviews?.length > 0 && (
              <RatingReview guideDetailData={guideDetailData} />
            )}
          </div>
        </>
      )}
    </>
  );
}
