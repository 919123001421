import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { guide_get_gallery } from "../../../config/endpoints";
import { Oval } from "react-loader-spinner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export default function GalleryPage() {
  const User = useSelector((state) => state.auth.SignupUser);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [Imgdata, setImgdata] = useState();

  const getGallery = () => {
    setLoading(true);
    const access_token = User.access_token;
    getApi(`${guide_get_gallery}/${id}`, {}, access_token).then((res) => {
      setImgdata(res?.data?.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    getGallery();
  }, []);

  console.log("dataaaaaa", Imgdata);

  return (
    <div className="container spacer-y contact-wrapper">
      <div className="text-center">
        <h2 className="p-0">Gallery</h2>
        <p>Check out the detail of place and experience</p>
      </div>

      {isLoading === true ? (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="gallery_content pt-5">
          <div className="row g-3">
            <div className="col-md-6">
              <div className="top-sticky">
                <div className="d-flex gap-4 justify-content-between">
                  <h5 className="mb-2">{Imgdata?.galleryDetails?.title}</h5>
                  <div className="d-flex gap-2">
                    {User?.role_name === "GUIDE" &&
                      !location?.state?.fromPreview &&
                      !location?.state?.formSocialProfile && (
                        <Link
                          to={`/edit-gallery/${Imgdata?.galleryDetails?.id}`}
                          state={{ id: Imgdata?.galleryDetails?.id }}
                          className="icon_button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="white"
                          >
                            <path d="M12.8995 6.85453L17.1421 11.0972L7.24264 20.9967H3V16.754L12.8995 6.85453ZM14.3137 5.44032L16.435 3.319C16.8256 2.92848 17.4587 2.92848 17.8492 3.319L20.6777 6.14743C21.0682 6.53795 21.0682 7.17112 20.6777 7.56164L18.5563 9.68296L14.3137 5.44032Z"></path>
                          </svg>
                        </Link>
                      )}
                    <Link onClick={() => navigate(-1)} className="icon_button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="white"
                      >
                        <path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="d-flex align-items-center pb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="rgba(71,84,103,1)"
                  >
                    <path d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"></path>
                  </svg>
                  <p className="m-0 ps-1">
                    {Imgdata?.galleryDetails?.location || "USA"}
                  </p>
                </div>
                <p>{Imgdata?.galleryDetails?.description}</p>
              </div>
            </div>
            <div className="col-md-6">
              {Imgdata?.galleryDetails?.gallery_media?.length === 4 && (
                <>
                  <div className="gallery_main_img">
                    {/* {Imgdata?.galleryDetails?.hero_image?.endsWith(".mp4") ||
                    Imgdata?.galleryDetails?.hero_image?.endsWith(".webm") ? (
                      <div className="heroexp_image_section">
                        <video controls className="w-100 h-100">
                          <source
                            src={Imgdata?.galleryDetails?.hero_image}
                            type={Imgdata?.galleryDetails?.hero_image.type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : ( */}
                    <div className="heroexp_image_section">
                      <img
                        src={Imgdata?.galleryDetails?.hero_image}
                        alt="hero_image"
                      />
                    </div>
                    {/* )} */}
                  </div>

                  <div className="row g-3 mt-0">
                    <div className="col-md-6 gap-2">
                      <div className="gallery_first_img">
                        {Imgdata?.galleryDetails?.gallery_media[0]
                          ?.media_type === "video" ? (
                          <video controls className="w-100 h-100">
                            <source
                              src={
                                Imgdata?.galleryDetails?.gallery_media[0]?.url
                              }
                              type={
                                Imgdata?.galleryDetails?.gallery_media[0]
                                  ?.url?.type
                              }
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={Imgdata?.galleryDetails?.gallery_media[0]?.url}
                            alt="img"
                          />
                        )}
                      </div>
                      <div className="row g-3 mt-0">
                        <div className="col-md-6">
                          {Imgdata?.galleryDetails?.gallery_media[1] && (
                            <div className="gallery_sec_img">
                              {Imgdata?.galleryDetails?.gallery_media[1]
                                ?.media_type === "video" ? (
                                <video controls className="w-100 h-100">
                                  <source
                                    src={
                                      Imgdata?.galleryDetails?.gallery_media[1]
                                        ?.url
                                    }
                                    type={
                                      Imgdata?.galleryDetails?.gallery_media[1]
                                        ?.url?.type
                                    }
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={
                                    Imgdata?.galleryDetails?.gallery_media[1]
                                      ?.url
                                  }
                                  alt="img"
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          {Imgdata?.galleryDetails?.gallery_media[2] && (
                            <div className="gallery_sec_img">
                              {Imgdata?.galleryDetails?.gallery_media[2]?.media_type === "video" ? (
                                <video controls className="w-100 h-100">
                                  <source
                                    src={
                                      Imgdata?.galleryDetails?.gallery_media[2]
                                        ?.url
                                    }
                                    type={
                                      Imgdata?.galleryDetails?.gallery_media[2]
                                        ?.url?.type
                                    }
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  src={
                                    Imgdata?.galleryDetails?.gallery_media[2]
                                      ?.url
                                  }
                                  alt="img"
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {Imgdata?.galleryDetails?.gallery_media[3] && (
                        <div className="gallery_last_img">
                          {Imgdata?.galleryDetails?.gallery_media[3]?.media_type === "video" ? (
                            <video controls className="w-100 h-100">
                              <source
                                src={
                                  Imgdata?.galleryDetails?.gallery_media[3]?.url
                                }
                                type={
                                  Imgdata?.galleryDetails?.gallery_media[3]?.url?.type
                                }
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={
                                Imgdata?.galleryDetails?.gallery_media[3]?.url
                              }
                              alt="img"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {Imgdata?.galleryDetails?.gallery_media?.length === 3 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.galleryDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.galleryDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.galleryDetails?.hero_image}
                              type={Imgdata?.galleryDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.galleryDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                  {Imgdata?.galleryDetails?.gallery_media?.map((item, i) => (
                    <div key={i} className="col-4">
                      <div className="gallery_first_img">
                        {item?.media_type === "video" ? (
                          <video controls className="w-100 h-100">
                            <source src={item?.url} type={item?.url?.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={item?.url} alt="img" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {Imgdata?.galleryDetails?.gallery_media?.length === 2 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.galleryDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.galleryDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.galleryDetails?.hero_image}
                              type={Imgdata?.galleryDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.galleryDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                  {Imgdata?.galleryDetails?.gallery_media?.map((item, i) => (
                    <div key={i} className="col-6">
                      <div className="gallery_first_img">
                        {item?.media_type === "video"? (
                          <video controls className="w-100 h-100">
                            <source src={item?.url} type={item?.url?.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={item?.url} alt="img" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {Imgdata?.galleryDetails?.gallery_media?.length === 1 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.galleryDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.galleryDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.galleryDetails?.hero_image}
                              type={Imgdata?.galleryDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.galleryDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                  {Imgdata?.galleryDetails?.gallery_media?.map((item, i) => (
                    <div key={i} className="col-12">
                      <div className="gallery_first_img">
                        {item?.media_type === "video" ? (
                          <video controls className="w-100 h-100">
                            <source src={item?.url} type={item?.url?.type} />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={item?.url} alt="img" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {Imgdata?.galleryDetails?.gallery_media?.length === 0 && (
                <div className="row g-2">
                  <div className="col-12">
                    <div className="gallery_main_img">
                      {/* {Imgdata?.galleryDetails?.hero_image?.endsWith(".mp4") ||
                      Imgdata?.galleryDetails?.hero_image?.endsWith(".webm") ? (
                        <div className="heroexp_image_section">
                          <video controls className="w-100 h-100">
                            <source
                              src={Imgdata?.galleryDetails?.hero_image}
                              type={Imgdata?.galleryDetails?.hero_image.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : ( */}
                        <div className="heroexp_image_section">
                          <img
                            src={Imgdata?.galleryDetails?.hero_image}
                            alt="hero_image"
                          />
                        </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
