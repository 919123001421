import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { TERMS_PAGE } from "../../../redux/slices/content/ContentSlice";
import parse from "html-react-parser";
import { Oval } from "react-loader-spinner";

function Terms() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.termsPage);
  const [loading, setLoading] = useState(false);

  const termsPageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=PRIVACY_POLICY&section=TERMS`)
      .then((res) => {
        dispatch(TERMS_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    termsPageData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="container spacer px-3 px-md-0">
          <div className="row mb-3">
            <div className="col-md-12"></div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="toc-blk bg-white br-20 p-4">
                <h1 className="h4">{data && data?.value?.title}</h1>
                {parse(`${data && data?.value?.description}`)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Terms;
