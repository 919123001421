import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import UploadMedia from "../createExperience/uploadMedia/UploadMedia";
import { ThreeDots } from "react-loader-spinner";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../../config/apiFunctions";
import { guide_create_gallery } from "../../../config/endpoints";

export default function CreateGallery() {
  const formOptions = {
    resolver: yupResolver(Schema),
  };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const User = useSelector((state) => state.auth.SignupUser);
  const toast = useToast();
  const navigate = useNavigate();

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [address, setAddress] = useState();
  const [files, setFiles] = useState([]);
  const [mainImg, setMainImg] = useState();
  const [mainImgBinary, setMainImgBinary] = useState();
  const [mainImgType, setMainImgType] = useState(null);
  const [multipleImages, setMultipleImages] = useState([]);
  const [btndisable, setBtnDisable] = useState(false);
  const [addImages, setAddImages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [getAddressData, setGetAddressData] = useState({
    lat: "",
    long: "",
    country: "",
    state: "",
    city: "",
  });

  let country = null;
  let state = null;
  let city = null;
  let lat;
  let long;

  const handleChangePlace = (e) => {
    setAddress();
    setInputValue(e);
    setShowSuggestions(true);
    setInputError(false)
  };

  const selectMainImg = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const file = files[0];
      const fileType = file.type;

      if (fileType.startsWith("image/")) {
        setMainImgType("image");
      } else if (fileType.startsWith("video/")) {
        setMainImgType("video");
      }
      const fileUrl = URL.createObjectURL(file);
      setMainImg(fileUrl);
    }

    setMainImgBinary(e.target.files[0]);
  };

  const handleSelectPlace = (address) => {
    setShowSuggestions(false);
    setAddressError(false);
    setAddress(address);
    setInputValue(address);
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]);

        const addressComponents = results[0].address_components;

        addressComponents.forEach((component) => {
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (
            component.types.includes("administrative_area_level_3") ||
            component.types.includes("postal_town")
          ) {
            city = component.long_name;
          }
        });
        lat = results[0].geometry.location.lat();
        long = results[0].geometry.location.lng();

        setGetAddressData({
          ...getAddressData,
          lat: lat,
          long: long,
          country: country,
          state: state,
          city: city || "",
        });

        // setGetAddressData({
        //   ...getAddressData,
        //   lat: lat,
        //   long: long,
        // });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleRemove = (i, isNewIndexvalue = -1) => {
    if (isNewIndexvalue) {
      const _UPLOAD_IMG_INDEX = i - (multipleImages.length - addImages.length);
      setAddImages(
        addImages.filter((item, _index) => _index !== _UPLOAD_IMG_INDEX)
      );
    }
    setMultipleImages((current) => {
      return current.filter((item, index) => index !== i);
    });
    setFiles((current) => {
      return current.filter((item, index) => index !== i);
    });
  };

  const changeMultipleFiles = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const newFiles = filesArray.map((file) => ({
        url: URL.createObjectURL(file),
        type: file.type,
      }));
      setFiles((prev) => [...prev, ...filesArray]);
      setAddImages((prev) => [...prev, ...filesArray]);
      setMultipleImages((prevImages) => prevImages.concat(newFiles));
    }
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  const onSubmit = (e) => {
    setBtnDisable(true);

    if (!inputValue) {
      setBtnDisable(false);
      setInputError(true);
    } else if (!address) {
      setBtnDisable(false);
      setAddressError(true);
    } else if (!mainImg) {
      toast({
        title: "Primary media is required",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (files.length > 4) {
      toast({
        title: "Secondary media length not be more than 4",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else {
      const formdata = new FormData();
      for (let i = 0; i < files.length; i++) {
        formdata.append("media", files[i]);
      }
      formdata.append("hero_image", mainImgBinary);
      formdata.append("title", e.title);
      formdata.append("location", address || "USA");
      formdata.append("description", e.description);
      postApi(guide_create_gallery, formdata, User?.access_token)
        .then((res) => {
          if (res.status === 200) {
            toast({
              title: res.data.message,
              status: "success",
              duration: 30000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => setBtnDisable(false),
            });
            setBtnDisable(false);
            reset();
            setMultipleImages([]);
            setAddImages([]);
            navigate(`/guide_detailed_page/${User?.id}`);
          }
        })
        .catch((err) => {
          setBtnDisable(false);
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        });
    }
  };
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <secton className="page-header-gradient">
      <div className="container spacer-y contact-wrapper">
        <h2 className="primary__color pb-4">Gallery</h2>
        <form className="traveler-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row g-3 flex-column-reverse flex-md-row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Give your post a title..."
                    {...register("title")}
                    className={`form-control theme-controls ${
                      errors.title ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.title?.message}
                  </div>
                </div>

                <div className="col-md-12 mb-4">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Location
                  </label>

                  {scriptLoaded && (
                    <PlacesAutocomplete
                      value={inputValue}
                      onChange={handleChangePlace}
                      onSelect={handleSelectPlace}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <div className="input-icon-grp">
                            <input
                              {...getInputProps({
                                placeholder: "Enter your location",
                                className:
                                  "location-search-input form-control theme-controls",
                                type: "text",
                              })}
                            />
                            <i class="fa-regular fa-location-dot"></i>
                          </div>
                          {showSuggestions && suggestions?.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {/* {loading && <p>Loading...</p>} */}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    onClick={() => {
                                      handleSelectPlace(suggestion.description);
                                    }}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  )}
                  {inputError && (
                    <div className="location_error">Location is required</div>
                  )}
                  {addressError && (
                    <div className="location_error">
                      Please select location from dropdown
                    </div>
                  )}
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="name"
                    placeholder="Enter description about your experience"
                    {...register("description")}
                    className={`form-control theme-controls ${
                      errors.description ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.description?.message}
                  </div>
                </div>

                <div className="col-lg-6 text-center pt-4">
                  {btndisable === true ? (
                    <button
                      type="submit"
                      disabled={btndisable}
                      className="button-primary email-send-trigger w-100"
                    >
                      <div className="d-flex justify-content-center">
                        <ThreeDots
                          height="20"
                          width="80"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={btndisable}
                      className="button-primary email-send-trigger w-100"
                    >
                      Save
                    </button>
                  )}
                </div>
                <div className="col-lg-6 text-center pt-4">
                  <button
                    type="button"
                    onClick={handleNavigate}
                    disabled={btndisable}
                    className="button-primary primary-outline w-100"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <UploadMedia
                multipleImages={multipleImages}
                mainImg={mainImg}
                selectMainImg={selectMainImg}
                setMainImg={setMainImg}
                errors={errors}
                handleChangeRemove={handleRemove}
                changeMultipleFiles={changeMultipleFiles}
                mainImgType={mainImgType}
              />
            </div>
          </div>
        </form>
      </div>
    </secton>
  );
}

const Schema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(2, "Too Short!")
    .trim(" ")
    .max(50, "Please enter 50 characters only"),
  location: Yup.string(),
  description: Yup.string().required("Description is required"),
});
