import React from "react";
import defaultImg from "../../../../assets/images/defaultUserImg.svg";
import { Star } from "../../../../config/common/common";
import moment from "moment/moment";

export default function RatingReview({ guideDetailData }) {
  return (
    <div className="py-4">
      <h5>Rating & Review</h5>

      <div className="row g-4">
        {guideDetailData?.guideDetails?.rating_and_reviews?.map((item, i) => (
          <div key={i} className="col-md-6">
            <div className="review_card">
              <div className="row">
                <div className="col-md-2">
                  <div className="reviewImg">
                    <img
                      src={
                        item?.ratingGivenUserDetails?.user_detail
                          ?.profile_picture
                          ? item?.ratingGivenUserDetails?.user_detail
                              ?.profile_picture
                          : defaultImg
                      }
                      alt="Img"
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="m-0 ">{item?.user_name}</p>
                  <p className="m-0 ">{moment(item?.createdAt).fromNow()}</p>
                  <Star stars={item?.ratings} />
                  <p>{item?.review_message}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
