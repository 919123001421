import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import ShareProfile from "../../../popup/shareProfile/ShareProfile";
import { useSelector } from "react-redux";
import LoginModal from "../../../popup/loginPopUp/LoginModal";
import FollowModal from "../../../popup/followModal/FollowModal";
import LikeUnlikeModal from "../../../popup/likeUnlikeModal/LikeUnlikeModal";

export default function LocaliteSpecialExp({
  experiencePostData,
  guideDetailData,
  handlePreviousPage,
  handleNextPage,
  pageNo,
  total,
  handleLikeUnlike,
}) {
  const [showProfileShareModal, setShowShowProfileShareModal] = useState(false);
  const [animatingPostId, setAnimatingPostId] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [qrValue, setQRValue] = useState("");
  const [copyText, setCopyText] = useState("");
  const [postId, setPostId] = useState();
  const User = useSelector((state) => state.auth.SignupUser);

  const { id } = useParams();

  const showShareProfileModal = (id) => {
    setShowShowProfileShareModal(true);
    setQRValue(`/experience-detail/${id}`);
    setCopyText(`/social-profile/${guideDetailData?.guideDetails?.id}`);
  };

  // console.log("experiencePostData", experiencePostData.rows[0].post_images[0].url.split('.').pop())
  
  return (
    <div className="pt-4">
      <div className="d-flex justify-content-between">
        <h5>Special Experience</h5>
        <div>
          <Link to={`/special-experience-list/${id}`} className="show_more_btn">
            <p className="m-0 pe-2">Show more</p>{" "}
            <IoIosArrowForward style={{ color: "#475467" }} />
          </Link>
        </div>
      </div>

      <div className="row py-4 g-4">
        {experiencePostData?.rows?.map((item, i) => (
          <div key={i} className="col-md-6">
            <div className="special_exp_card">
              {item.post_images.length === 0 ? (
                <div className="row g-3">
                  <div className="col-md-12">
                    {/* {item.hero_image?.endsWith(".mp4") ||
                    item.hero_image?.endsWith(".webm") ? (
                      <div className="hero_image_section">
                        <video controls className="w-100 h-100">
                          <source
                            src={item.hero_image}
                            type={item.hero_image.type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : ( */}
                    <div className="hero_image_section">
                      <img src={item.hero_image} alt="hero_image" />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              ) : (
                <div className="row g-3">
                  <div className="col-md-6">
                    {/* {item.hero_image?.endsWith(".mp4") ||
                    item.hero_image?.endsWith(".webm") ? (
                      <div className="hero_image_section">
                        <video controls className="w-100 h-100">
                          <source
                            src={item.hero_image}
                            type={item.hero_image.type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : ( */}
                    <div className="hero_image_section">
                      <img src={item.hero_image} alt="hero_image" />
                    </div>
                    {/* )} */}
                  </div>
                  {item.post_images.length >= 2 && (
                    <div className="col-md-6 d-flex flex-column gap-3">
                      {item.post_images?.slice(0, 2)?.map((img, i) => (
                        <div key={i} className="rightTop_img_section">
                          {img.media_type === "video" ? (
                            <video controls className="w-100 h-100">
                              <source src={img.url} type={img.url.type} />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={img.url} alt="img" />
                          )}
                          {/* {img?.url.split('.').pop() === "mp4" ? (
                            <video controls className="w-100 h-100">
                              <source src={img.url} type={img.url.type} />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={img.url} alt="img" />
                          )} */}
                        </div>
                      ))}
                    </div>
                  )}
                  {item.post_images.length === 1 && (
                    <div className="col-md-6">
                      {item.post_images?.map((img, i) => (
                        <div
                          key={i}
                          className={`${
                            item.post_images.length === 1 && "h-100"
                          } rightTop_img_section`}
                        >
                          {img.media_type === "video"  ? (
                            <video controls className="w-100 h-100">
                              <source src={img.url} type={img.url.type} />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={img.url} alt="img" />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              <div className="specialExp_content">
                <div className="d-flex justify-content-between align-items-center ">
                  <div className="d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="rgba(71,84,103,1)"
                    >
                      <path d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"></path>
                    </svg>
                    <p className="m-0 ps-1">{item.location || "USA"}</p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div onClick={() => showShareProfileModal(item.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="rgba(71,84,103,1)"
                      >
                        <path d="M13.5759 17.2714L8.46576 14.484C7.83312 15.112 6.96187 15.5 6 15.5C4.067 15.5 2.5 13.933 2.5 12C2.5 10.067 4.067 8.5 6 8.5C6.96181 8.5 7.83301 8.88796 8.46564 9.51593L13.5759 6.72855C13.5262 6.49354 13.5 6.24983 13.5 6C13.5 4.067 15.067 2.5 17 2.5C18.933 2.5 20.5 4.067 20.5 6C20.5 7.933 18.933 9.5 17 9.5C16.0381 9.5 15.1669 9.11201 14.5343 8.48399L9.42404 11.2713C9.47382 11.5064 9.5 11.7501 9.5 12C9.5 12.2498 9.47383 12.4935 9.42408 12.7285L14.5343 15.516C15.167 14.888 16.0382 14.5 17 14.5C18.933 14.5 20.5 16.067 20.5 18C20.5 19.933 18.933 21.5 17 21.5C15.067 21.5 13.5 19.933 13.5 18C13.5 17.7502 13.5262 17.5064 13.5759 17.2714Z"></path>
                      </svg>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                      <svg
                        onClick={() => {
                          if (User?.access_token) {
                            handleLikeUnlike(item.id, item.likedPost);
                            if (item.likedPost === 0) {
                              setAnimatingPostId(item.id);
                              setTimeout(() => setAnimatingPostId(null), 1000);
                            }
                          } else {
                            setShowLoginModal(true);
                            setPostId(item.id);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill={
                          item.likedPost
                            ? "rgba(182,11,11,1)"
                            : "rgba(71,84,103,1)"
                        }
                      >
                        {item.likedPost === 1 ? (
                          <path d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853Z"></path>
                        ) : (
                          <path d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853ZM18.827 6.1701C17.3279 4.66794 14.9076 4.60701 13.337 6.01687L12.0019 7.21524L10.6661 6.01781C9.09098 4.60597 6.67506 4.66808 5.17157 6.17157C3.68183 7.66131 3.60704 10.0473 4.97993 11.6232L11.9999 18.6543L19.0201 11.6232C20.3935 10.0467 20.319 7.66525 18.827 6.1701Z"></path>
                        )}
                      </svg>
                      <p className="mb-0">{item.likesCount}</p>
                    </div>
                    {animatingPostId === item.id && (
                      <div className="heart-animation">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="50"
                          height="50"
                          fill="rgba(182,11,11,1)"
                        >
                          <path d="M12.001 4.52853C14.35 2.42 17.98 2.49 20.2426 4.75736C22.5053 7.02472 22.583 10.637 20.4786 12.993L11.9999 21.485L3.52138 12.993C1.41705 10.637 1.49571 7.01901 3.75736 4.75736C6.02157 2.49315 9.64519 2.41687 12.001 4.52853Z"></path>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3 pt-2 mb-4">
                  <div className="d-flex align-items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="rgba(71,84,103,1)"
                    >
                      <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
                    </svg>
                    <p className="mb-0">{guideDetailData?.AvgRatings}</p>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="rgba(71,84,103,1)"
                    >
                      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path>
                    </svg>
                    <p className="mb-0">{item.duration}</p>
                  </div>
                </div>
                <div>
                  <h6>{item.title}</h6>
                  <p>
                    {item?.description?.length > 100
                      ? `${item?.description?.slice(0, 100)}...`
                      : item?.description}
                  </p>
                  <Link
                    to={`/experience-detail/${item?.id}`}
                    className="primary__color fw-bold"
                  >
                    See more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center d-flex align-items-center gap-3 justify-content-center">
        {pageNo > 1 && (
          <button
            className="button-primary p-2"
            style={{ minWidth: "auto" }}
            onClick={handlePreviousPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={24}
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
            </svg>
          </button>
        )}

        {pageNo < total && (
          <button
            className="button-primary p-2"
            style={{ minWidth: "auto" }}
            onClick={handleNextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={24}
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
            </svg>
          </button>
        )}
      </div>
      <ShareProfile
        show={showProfileShareModal}
        onHide={() => setShowShowProfileShareModal(false)}
        guideDetailData={guideDetailData}
        qrValue={qrValue}
        copyText={copyText}
        title="Special Experience Sharing"
      />
      {/* <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      /> */}
      <LikeUnlikeModal
        postId={postId}
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
    </div>
  );
}
